import React, { useEffect, useRef, useState } from "react";
import {
  categorieService,
  courService,
  discutionService,
  examenService,
  formationChapitre,
  formationPaiement,
  formationService,
  participerService,
  qcmService,
  questionService,
  seanceTravail,
  sliderService,
  sousCatService,
  suiveService,
  videoService,
} from "../../_services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function useForma(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // alert(slug)
  const {
    data: formations,
    error,
    isLoading: isLoad,
  } = useQuery({
    queryKey: ["formations"],
    queryFn: () =>
      formationService.tousFormation().then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });

  const [unFormation, setFormation] = useState([]);
  const {
    // data: categorie,
  } = useQuery({
    queryKey: ["formations", slug],
    queryFn: () =>
      formationService.unFormation(slug).then((res) => {
        if (res.data.etat === true) {
          setFormation(res.data.donnee);
          
        } else {
          // toast.error("Les identifiants sont incorrects");
        }
      }),
    onerror: (error) => console.log(error),
  });

  // const {
  //   data: formaInstruc,
  //   // error,
  //   isLoading,
  // } = useQuery({
  //   queryKey: ["formations", slug],
  //   queryFn: () =>
  //     formationService.allFormation(slug)
  //     .then((res) => res.data.donnee),
  //   onerror: (error) => console.log(error),
  // });

  // useEffect(()=>{

  //   if(flag.current===false){
  //       formationService.allFormation(slug)
  //     .then(res => {
  //       if(res.data.etat===true){
  //         setFo(res.data.donnee);
  //       } else {
  //         // toast.error("Nom trouver");
  //       }
  //     })
  //     .catch(err => console.log(err))
  //   }
  //   return () => flag.current = true;;;

  // },[slug]);
  const [formaInstruc, setFormationInstruc] = useState([]);
  const {
    data: formaIns,
    isLoading,
    isError
  } = useQuery({
    queryKey: ["fo", slug],
    queryFn: () =>
      formationService.allFormation(slug).then((res) => {
        if (res.data.etat === true) {
          return res.data.donnee; 
        } else {
          // toast.error("Les identifiants sont incorrects");
        }
      }),
    onerror: (error) => console.log(error),
  });

  useEffect(() => {
    if (formaIns) {
      setFormationInstruc(formaIns);
    }
  }, [formaIns]);

  const ajout = useMutation({
    mutationFn: (data) => {
      return formationService.addFormation(data);
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["formations"] });
      toast.success("Publication ajoutée avec succès");
    },
  });
  const create = (nom) => {
    ajout.mutate(nom);
  };

  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return formationService
        .updateFormation(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["formations"] });
            // navigate("/admin/formation/index")
            navigate(-1);
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateForm = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return {
    unFormation,
    formations,
    isLoad,
    isLoading,
    isError,
    updateForm,
    formaInstruc,
    setFormationInstruc,
    create,
    setFormation,
  };
}

export function useChapitre(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();

  const {
    data: chapitre,
    // error,
    isLoading,
  } = useQuery({
    queryKey: ["chap", slug],
    queryFn: () =>
      formationChapitre.allChapitre(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });

  const del = useMutation({
    mutationFn: (post) => {
      return formationChapitre.deleteChapitre(post).then((res) => {
        if (res.data.etat !== true) {
          // toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["chap"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteChapitre = (post) => {
    del.mutate(post);
  };

  const add = useMutation({
    mutationFn: (chap) => {
      return formationChapitre
        .addChapitre(chap)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["chap"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addChapitre = (chap) => {
    add.mutate(chap);
  };

  const modif = useMutation({
    mutationFn: (chap) => {
      return formationChapitre
        .updateChapitre(chap)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            navigate(-1);
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateChapitre = (chap) => {
    modif.mutate(chap);
  };

  return { chapitre, deleteChapitre, addChapitre, updateChapitre, isLoading };
}

export function useSlider(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: sliders,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["slider"],
    queryFn: () => sliderService.allSlider().then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return sliderService.deleteSlider(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["slider"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteSlider = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return sliderService
        .addSlider(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["slider"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addSlider = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (chap) => {
      return formationChapitre
        .updateChapitre(chap)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateChapitre = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { sliders, deleteSlider, addSlider, updateChapitre, isLoading };
}

export function useQuestion(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: questions,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["question", slug],
    queryFn: () =>
      questionService.allQuestion(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return questionService.deleteQuestion(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["question"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteQuestion = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return questionService
        .addQuestion(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["question"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addQuestion = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (chap) => {
      return formationChapitre
        .updateChapitre(chap)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateChapitre = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { questions, deleteQuestion, addQuestion, updateChapitre, isLoading };
}

export function useCour(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: cours,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["cours", slug],
    queryFn: () => courService.allCour(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return courService.deleteCour(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        } else {
          useQ.invalidateQueries({ queryKey: ["cours"] });
          // toast.success("formations supprimée avec succès")
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["cours"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteCour = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return courService
        .addCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["cours"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addCour = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return courService
        .updateCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateChapitre = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { cours, deleteCour, addCour, updateChapitre, isLoading };
}

export function useFormaAcheter(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: payer,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["FormaPaie", slug],
    queryFn: () =>
      formationPaiement.allPaiement(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return courService.deleteCour(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        } else {
          useQ.invalidateQueries({ queryKey: ["FormaPaie"] });
          // toast.success("formations supprimée avec succès")
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["FormaPaie"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteCour = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return courService
        .addCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["FormaPaie"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addCour = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return courService
        .updateCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateChapitre = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { payer, deleteCour, addCour, updateChapitre, isLoading };
}

export function useDiscution(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: discuts,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["Discutions", slug],
    queryFn: () =>
      discutionService.getDiscution(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return discutionService.deleteDiscution(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["Discutions"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteDiscut = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return discutionService
        .addDiscution(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["Discutions"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addDiscut = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return courService
        .updateCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["Discutions"] });
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateDiscut = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { discuts, deleteDiscut, addDiscut, updateDiscut, isLoading };
}

export function useSousCat(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: sous_categorie,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["souscat", slug],
    queryFn: () =>
      sousCatService.getSousCat(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });

  const [sous_categories, setFo] = useState([]);

  const flag = useRef(false);

  useEffect(() => {
    if (flag.current === false) {
      sousCatService
        .allSousCat(slug)
        .then((res) => {
          if (res.data.etat === true) {
            setFo(res.data.donnee);
          } else {
            // toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    }
    return () => (flag.current = true);
  }, [slug]);

  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return sousCatService.deleteSousCat(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["souscat"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteSousCat = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return sousCatService
        .addSousCat(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["souscat"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addSousCat = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (slug) => {
      return sousCatService
        .updateSousCat(slug)
        .then((res) => {
          
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["souscat"] });
            // navigate(`/admin/formation/sous-categorie/${data}`)
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateSousCat = (slug) => {
    modif.mutate(slug);
  };
  // fin

  return {
    sous_categorie,
    sous_categories,
    deleteSousCat,
    addSousCat,
    updateSousCat,
    isLoading,
    setFo,
  };
}

export function useCategorie(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: categories,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["categorie", slug],
    queryFn: () =>
      categorieService.allCategorie(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });

  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return categorieService.deleteCategorie(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["categorie"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteCat = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return categorieService
        .addCategorie(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["categorie"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addCat = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return categorieService
        .updateCategorie(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["categorie"] });
            // navigate("/admin/formation/categorie");
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateCat = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { categories, deleteCat, addCat, updateCat, isLoading };
}

export function useSuive(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: suives,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["suive", slug],
    queryFn: () => suiveService.allSuive(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return suiveService.deleteSuive(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["suive"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deletesuive = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return suiveService
        .addSuive(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["suive"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addsuive = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return courService
        .updateCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["suive"] });
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updatesuive = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { suives, deletesuive, addsuive, updatesuive, isLoading };
}

export function useSeanceTravail(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: seance,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["suive", slug],
    queryFn: () => seanceTravail.allSeance(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return discutionService.deleteDiscution(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["suive"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteSeance = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return suiveService
        .addSuive(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["suive"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addSeance = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return courService
        .updateCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["suive"] });
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateSeance = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { seance, deleteSeance, addSeance, updateSeance, isLoading };
}

export function useExamen(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: examen,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["examen"],
    queryFn: () => examenService.tousExamen().then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour l'affichage

  const {
    data: exam,

    isLoading: isLoad,
  } = useQuery({
    queryKey: ["examen", slug],
    queryFn: () => examenService.allExamen(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return discutionService.deleteDiscution(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["suive"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteSeance = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return suiveService
        .addSuive(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["suive"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addSeance = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return courService
        .updateCour(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["suive"] });
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateSeance = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { examen, exam, deleteSeance, addSeance, updateSeance, isLoading };
}

export function useQcm(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: qcm_s,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["QCM", slug],
    queryFn: () => qcmService.allQcm(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour l'affichage
  const [unQcm, setQcm] = useState([]);
  
  const {
    // data: categorie,
  } = useQuery({
    queryKey: ["QCMmm", slug],
    queryFn: () =>
      qcmService.getUnQcm(slug).then((res) => {
        if (res.data.etat === true) {
          setQcm(res.data.donnee);
          
        } else {
          // toast.error("Les identifiants sont incorrects");
        }
      }),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return qcmService.deleteQcm(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["QCM"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteQcm = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return qcmService
        .addQcm(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["QCM"] });
           
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["QCM"] });
      toast.success("Ajoutée avec succès");
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addQcm = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return qcmService
        .updateQcm(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["QCM"] });
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateQcm = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return { qcm_s, unQcm, setQcm, deleteQcm, addQcm, updateQcm, isLoading };
}

export function useParticiper(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: participers,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["participer"],
    queryFn: () =>
      participerService.tousParticiper().then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour l'affichage
  const {
    data: qcm,

    isLoading: isLoad,
  } = useQuery({
    queryKey: ["participer", slug],
    queryFn: () => qcmService.getUnQcm(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return participerService.deleteParticiper(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        } else {
          useQ.invalidateQueries({ queryKey: ["participer"] });
        }
      });
    },
    // onError: (error) => {
    // toast.error("Une erreur est survenue",error);
    // },
    // onSuccess: () => {
    //   useQ.invalidateQueries({queryKey: ["participer"]});
    //   toast.danger("formations supprimée avec succès");
    // },
  });
  const deletePartcip = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return participerService
        .addParticiper(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["participer"] });
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addPartcip = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
    mutationFn: (data) => {
      return participerService
        .updateParticiper(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["participer"] });
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updatePartcip = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return {
    participers,
    qcm,
    deletePartcip,
    addPartcip,
    updatePartcip,
    isLoading,
  };
}

export function useVideo(slug) {
  let navigate = useNavigate();
  const useQ = useQueryClient();
  // pour l'affichage
  const {
    data: videos,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["Video", slug],
    queryFn: () => videoService.allVideo(slug).then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour l'affichage
  const [unVideo, setVideo] = useState([]);
  
  const {
    // data: categorie,
  } = useQuery({
    queryKey: ["VideoMmm", slug],
    queryFn: () =>
      videoService.getUnVideo(slug).then((res) => {
        if (res.data.etat === true) {
          setVideo(res.data.donnee);
          
        } else {
          // toast.error("Les identifiants sont incorrects");
        }
      }),
    onerror: (error) => console.log(error),
  });
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
      return videoService.deleteVideo(post).then((res) => {
        if (res.data.etat !== true) {
          toast.error(res.data.message);
        }
      });
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["Video"] });
      // toast.success("formations supprimée avec succès");
    },
  });
  const deleteVideo = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
      return qcmService
        .addQcm(data)
        .then((res) => {
          if (res.data.etat === true) {
            useQ.invalidateQueries({ queryKey: ["Video"] });
           
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onSuccess: () => {
      useQ.invalidateQueries({ queryKey: ["Video"] });
      toast.success("Ajoutée avec succès");
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const addVideo = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modifVideo = useMutation({
    mutationFn: (data) => {
      return videoService
        .updateVideo(data)
        .then((res) => {
          if (res.data.etat === true) {
            toast.success("Modification reuissi");
            useQ.invalidateQueries({ queryKey: ["Video"] });
            navigate(-1)
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
  });
  const updateVideo = (chap) => {
    modifVideo.mutate(chap);
  };
  // fin

  return { videos, unVideo, setVideo, deleteVideo, addVideo, updateVideo, isLoading };
}