import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAnc_Type, { useAnc_Document, useAnc_Matiere, useAnc_Niveau, useAnc_Pays } from "../../../../components/UseContext/useAncien";
import { BASE } from "../../../../_services/caller.service";

export default function DocModif() {
    let { id } = useParams();
    const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
    const { unDocument, setDocument, updateDocument } = useAnc_Document(id);
    const top = {
        all: "all",
      };
    const { Type } = useAnc_Type(top);
    const { niveau } = useAnc_Niveau();
    const { matiere } = useAnc_Matiere();
    const { pays } = useAnc_Pays();

    const [base64Image, setBase64Image] = useState("");
    const [base64Doc, setBase64Doc] = useState("");

    const onChange = (e) => {
        setDocument({
          ...unDocument,
          [e.target.name]: e.target.value,
        });
      };

    const handleMinChange = (e) => {
        const file = e.target.files[0];
    
        // Convertir l'image en base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
    
        reader.readAsDataURL(file);
      };
      const DocumentChange = (e) => {
        const file = e.target.files[0];
    
        // Convertir l'image en base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Doc(reader.result);
        };
    
        reader.readAsDataURL(file);
      };

    const onSubmit = (e) => {
        e.preventDefault();
        
        unDocument["id"] = id;
        if (base64Doc.includes("base64")) {
          unDocument["document"] = base64Doc;
        } else {
          delete unDocument["document"];
        }
    
        if (base64Image.includes("base64")) {
          unDocument["miniature"] = base64Image;
        } else {
          delete unDocument["miniature"];
        }
        console.log(" hh kii",unDocument)
        updateDocument(unDocument);
      };

      let url = BASE(unDocument.miniature)
  return (
    <div className="modal-content py-5 mx-5">
      <div className="modal-header">
        <h4 className="modal-title mb-0" id="newCatgoryLabel">
          Nouveau Document
        </h4>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmit}>
          <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
              Nom
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="nom"
              onChange={onChange}
              className="form-control"
              value={unDocument.nom}
              required
            />
          </div>

          <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
              Choisissez le Type
            </label>
            <select
              class="form-select "
              name="type_id"
              onChange={onChange}
              aria-label="Default select example"
            >
              <option selected>{unDocument.type}</option>
              {Type?.length > 0
                ? Type.map((post) => (
                    <option value={post.id}>{post.nom}</option>
                  ))
                : "Pas de Type"}
            </select>
          </div>

          <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
              Choisissez le Niveau
            </label>
            <select
              class="form-select "
              name="niveau_id"
              onChange={onChange}
              aria-label="Default select example"
            >
              <option selected>{unDocument.niveau}</option>
              {niveau?.length > 0
                ? niveau.map((post) => (
                    <option value={post.id}>{post.nom}</option>
                  ))
                : "Pas de Type"}
            </select>
          </div>

          <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
              Choisissez le Matiere
            </label>
            <select
              class="form-select "
              name="matiere_id"
              onChange={onChange}
              aria-label="Default select example"
            >
              <option selected>{unDocument.matiere}</option>
              {matiere?.length > 0
                ? matiere.map((post) => (
                    <option value={post.id}>{post.nom}</option>
                  ))
                : "Pas de Type"}
            </select>
          </div>

          <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
              Choisissez le Pay
            </label>
            <select
              class="form-select "
              name="pays_id"
              onChange={onChange}
              aria-label="Default select example"
            >
              <option selected>{unDocument.pays}</option>
              {pays?.length > 0
                ? pays.map((post) => (
                    <option value={post.id}>{post.nom}</option>
                  ))
                : "Pas de Type"}
            </select>
          </div>

          <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
              prix
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="prix"
              onChange={onChange}
              className="form-control"
              value={unDocument.prix}
              required
            />
          </div>

          <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
              annee
              <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              name="annee"
              onChange={onChange}
              className="form-control"
              value={unDocument.annee}
              required
            />
          </div>

          <div class="mb-3">
            <label for="formFileSm" class="form-label">
              Le Document de l'ancien sujet
            </label>
            <input
              class="form-control form-control-sm"
              onChange={DocumentChange}
              type="file"
            />
          </div>
          <div className="mb-3 mb-2">
                        <label className="form-label" htmlFor="title">
                        Image                       
                        
                        </label>
                        <div>
                        <img src={url} alt="course" className="rounded img-4by3-lg" />
                        </div>
                  </div>
          <div class="mb-3">
            <label for="formFileSm" class="form-label">
              Une Image de l'ancien sujet
            </label>
            <input
              class="form-control form-control-sm"
              onChange={handleMinChange}
              type="file"
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              
            >
              Ajouter
            </button>
            <button type="button" className="btn btn-secondary mx-1" onClick={handleGoBack}>Retour</button>

          </div>
        </form>
      </div>
    </div>
  );
}
