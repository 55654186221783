import React, { useState } from 'react'

import CardFormation from './CardFormation';

import useForma from '../../../components/UseContext/useForma';

export default function Formation() {

  // const { formations } = useContext(FormationContext)  
  const { formations } = useForma()
  const [search, setSearch] = useState("");
  if(formations){
  
  const handleSearch = (e) => {
    let value = e.target.value;
    value.length > 2 && setSearch(value)
  }
  
  const publishedPosts = formations.filter(post => post.ajout_terminer === true);
    const nombre = publishedPosts?.length;

  return (
    <>      
     
      {/* Container fluid */}
      <section className="container-fluid p-4">
        <div className="row">
          {/* Page Header */}
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-3 mb-3 d-md-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">Liste des formations</h1>
                {/* Breadcrumb */}
                
              </div>
              {/* <div>
                <Link to="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newFormat">Add New Category</Link>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-lg-4 col-md-12 col-12">
          {/* Card */}
          <div className="card mb-4">
            <div className="p-4">
              <span className="fs-6 text-uppercase fw-semibold">Le nombre formation</span>
              <h2 className="mt-4 fw-bold mb-1 d-flex align-items-center h1 lh-1">{nombre}</h2>
              {/* <span className="d-flex justify-content-between align-items-center">
                <span>New this month</span>
                <span className="badge bg-info ms-2">120+</span>
              </span> */}
            </div>
          </div>
        </div>
          <div className="col-lg-12 col-md-12 col-12">
            {/* Card */}
            <div className="card mb-4">
              {/* Card header */}
              <div className="card-header border-bottom-0">
                {/* Form */}
                <form className="d-flex align-items-center">
                  <span className="position-absolute ps-3 search-icon">
                    <i className="fe fe-search" />
                  </span>
                  <input type="search" className="form-control ps-6" onChange={handleSearch} placeholder="Search Course formation" />
                </form>
              </div>
              {/* Table */}
              <div className="table-responsive border-0 overflow-y-hidden">
                <table className="table mb-0 text-nowrap table-centered table-hover table-with-checkbox">
                  <thead className="table-light">
                  <tr>
                    <th>Nom</th>
                    <th>Prix</th>
                    <th>Instructeur</th>
                    <th>Nombre heure</th>
                    <th>Categorie</th>
                    <th>Photo</th>
                    
                    <th>AJout Terminer</th>
                    <th />
                  </tr>
                  </thead>
                  <tbody>
     
                
                  {
                  formations
                  .filter((val) => {
                    return val.nom.toLowerCase().includes(search.toLowerCase());
                  }).map((formation, index) => {
                    return <CardFormation key={index} formation={formation} />
                  })}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      
    </>
  )
}
}
