import React from "react";
import { useParams } from "react-router-dom";
import { useBibliotheque_Livre } from "../../../components/UseContext/useAncien";
import CarDetail from "./CarDetail";

export default function DetailLivre() {
    let { slug } = useParams();
    const top = {
        slug: slug,
    };

    const { Livre } = useBibliotheque_Livre(top);
    
  return (
    <div>
      {/*learn today*/}
      <section className="py-lg-8 py-6">
        <div className="container my-lg-8">
            {Livre?.map((post) => {
                return <CarDetail post={post} />
            })}
        </div>
      </section>
      {/*learn today*/}
    </div>
  );
}
