import React from 'react'
import { BASE } from '../../_services/caller.service'

export default function SliderImg({formation}) {
    
    let url = BASE(formation.image)
  return (
    <>
    <div class="col-xl-7 col-lg-6 col-md-12 text-lg-end text-center">
        <img src={url} alt="heroimg" class="img-fluid" />
    </div>
    </>
  )
}
