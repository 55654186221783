import React from 'react'
import useUtilisateur from '../../../../components/UseContext/useUtilisateur';
import { useBibliotheque_Aut } from '../../../../components/UseContext/useAncien';
import { useNavigate, useParams } from 'react-router-dom';

export default function AuteurModif() {
    let { id } = useParams()
    const { connect } = useUtilisateur()

    const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

    const {unAuteur, setAuteur, updateAut} = useBibliotheque_Aut(id)

    const onChangeQcm = (e) => {
        setAuteur({
            ...unAuteur,
            [e.target.name]: e.target.value
        })
    } 

    const onSubmitQcm = (e) => {
        e.preventDefault();
        
        unAuteur["admin_id"] = connect
        
        updateAut(unAuteur);
      };
  return (
    <div className="modal-content py-5 mx-5">
        <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Ajout d'un auteur</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmitQcm}>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Nom
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='nom' onChange={onChangeQcm} className="form-control" value={unAuteur.nom} />
                
            </div>
            
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Description
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='description' onChange={onChangeQcm} className="form-control" value={unAuteur.description} />
                
                
            </div>
            
            <div>
            <button type="submit" className="btn btn-primary">Ajouter</button>
            <button type="button" className="btn btn-secondary mx-1" onClick={handleGoBack}>Retour</button>
            
            </div>
        </form>
        </div>
        </div>
  )
}
