import React from 'react'
import { useAnc_Document, useAnc_PaieDoc } from '../../../components/UseContext/useAncien'
import CardListDoc from '../../Admin/Ancien_Sujet/Document/CardListDoc'
import DocCard from './DocCard'
import { accountService } from '../../../_services'

export default function MesDocument() {
    
    const user = accountService.getToken()

    const top = {
        "utilisateur_id": user,
    }

    const {payer} = useAnc_PaieDoc(top)

    console.log(payer)
  return (
    <>
    <div>

        <div class="row">
        {payer?.length > 0 ? 
            payer.map((post) => {
                return <DocCard post={post} />
            })
            : 'Pas de Document'
            }
            
        </div>
    </div>
    </>
  )
}
