import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ancienDocument, ancienMatiere, ancienNiveau, ancienPaieDoc, ancienPay, ancienType, documentPaiement, formationPaiement } from "../../_services";
import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import { bibliothequeAuteur, bibliothequeCategorie, bibliothequeLivre } from "../../_services/bibliotheque.numerique";
import { useNavigate } from "react-router-dom";

export default function useAnc_Type(slug) {
  let navigate = useNavigate()
  const [UnType, setType] = useState([])
    const useQ = useQueryClient();

    //   pour la recuperation de tous les types
    
    const {
    data: Type,
    error,
    isLoading,
    } = useQuery({
    queryKey: ["Type", slug],
    queryFn: () =>
    ancienType.allType(slug)
    .then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
    });

    const {
      // data: categorie,
      
  } = useQuery({
    queryKey: ["categorie", slug],
    queryFn: () =>
      ancienType.getType(slug)
    .then(res => {
      if(res.data.etat===true){
        setType(res.data.donnee);
          
      } else {
          toast.error("Les identifiants sont incorrects");
      }
  }),
    onerror: (error) => console.log(error),
  });
     
    
    // fin

    // pour l'ajout

    const add = useMutation({
    mutationFn: (data) => {
    return ancienType.addType(data)
    .then(res => {
        if(res.data.etat===true){
            useQ.invalidateQueries({queryKey: ["Type"]});
            toast.success("enregister");
        } else {
            toast.error("Nom trouver");
        }
        })
        .catch(err => console.log(err))
    },
    onError: (error) => {
    toast.error("Une erreur est survenue0");
    },
    });
    const addType = (data) => {
    add.mutate(data);
    };

    // fin

    // suppression

    const del = useMutation({
        mutationFn: (data) => {
        return ancienType.deleteType(data)
        .then(res => {
            if(res.data.etat!==true){
              toast.error(res.data.message);
            } 
          })
        },
        onError: (error) => {
        toast.error("Une erreur est survenue",error);
        },
        onSuccess: () => {
        useQ.invalidateQueries({queryKey: ["Type"]});
        // toast.success("formations supprimée avec succès");
        },
    });

    const deleteType = (data) => {
    del.mutate(data);
    };

    // fin

    // modification

    const modif = useMutation({
        mutationFn: (data) => {
        return ancienType.updateType(data)
        .then(res => {
            if(res.data.etat===true){
              toast.success("Modification reuissi");
              navigate(-1)
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },
        // onSuccess: () => {
        // useQ.invalidateQueries("Type");
        // toast.success("Type supprimée avec succès");
        // },
    });
    const updateType = (data) => {
    modif.mutate(data);
    };

    // fin
    
      
    return {Type, addType, deleteType, UnType, setType, updateType, isLoading};
  }

export function useAnc_Niveau(slug) {
    const useQ = useQueryClient();
    let navigate = useNavigate()
    //   pour la recuperation de tous les types
    const {
    data: niveau,
    error,
    isLoading,
    } = useQuery({
    queryKey: ["Niveau"],
    queryFn: () =>
    ancienNiveau.allNiveau()
    .then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
    });
    
    const [unNiveau, setNiveau] = useState([])
    const {
      // data: categorie,
      
  } = useQuery({
    queryKey: ["Niveau", slug],
    queryFn: () =>
      ancienNiveau.getNiveau(slug)
    .then(res => {
      if(res.data.etat===true){
        setNiveau(res.data.donnee);
      } else {
          toast.error("Les identifiants sont incorrects");
      }
  }),
    onerror: (error) => console.log(error),
  });
      
    // fin

    // pour l'ajout

    const add = useMutation({
    mutationFn: (data) => {
    return ancienNiveau.addNiveau(data)
    .then(res => {
        if(res.data.etat===true){
            useQ.invalidateQueries({queryKey: ["Niveau"]});
            toast.success("enregistrer");
        } else {
            toast.error("Nom trouver");
        }
        })
        .catch(err => console.log(err))
    },
    onError: (error) => {
    toast.error("Une erreur est survenue0");
    },
    });
    const addNiveau = (data) => {
    add.mutate(data);
    };

    // fin

    // suppression

    const del = useMutation({
        mutationFn: (data) => {
        return ancienNiveau.deleteNiveau(data)
        .then(res => {
            if(res.data.etat!==true){
              toast.error(res.data.message);
            } 
          })
        },
        onError: (error) => {
        toast.error("Une erreur est survenue",error);
        },
        onSuccess: () => {
        useQ.invalidateQueries({queryKey: ["Niveau"]});
        // toast.success("formations supprimée avec succès");
        },
    });

    const deleteNiveau = (data) => {
    del.mutate(data);
    };

    // fin

    // modification

    const modif = useMutation({
        mutationFn: (data) => {
        return ancienNiveau.updateNiveau(data)
        .then(res => {
            if(res.data.etat===true){
              toast.success("Modification reuissi");
              navigate(-1)
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },
        // onSuccess: () => {
        // useQ.invalidateQueries("Type");
        // toast.success("Type supprimée avec succès");
        // },
    });
    const updateNiveau = (data) => {
    modif.mutate(data);
    };

    // fin
    
      
    return {niveau, unNiveau, setNiveau, addNiveau, deleteNiveau, updateNiveau, isLoading};
  }

export function useAnc_Matiere(slug) {
    const useQ = useQueryClient();
    let navigate = useNavigate()
    //   pour la recuperation de tous les types
    const {
    data: matiere,
    error,
    isLoading,
    } = useQuery({
    queryKey: ["Matiere"],
    queryFn: () =>
    ancienMatiere.allMatiere()
    .then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
    });

    const [unMatiere, setMatiere] = useState([])
    const {
      // data: categorie,
      
  } = useQuery({
    queryKey: ["categorie", slug],
    queryFn: () =>
      ancienMatiere.getMatiere(slug)
    .then(res => {
      if(res.data.etat===true){
        setMatiere(res.data.donnee);
          
      } else {
          toast.error("Les identifiants sont incorrects");
      }
  }),
    onerror: (error) => console.log(error),
  });
    // fin

    // pour l'ajout

    const add = useMutation({
    mutationFn: (data) => {
    return ancienMatiere.addMatiere(data)
    .then(res => {
      if(res.data.etat===true){
        useQ.invalidateQueries({queryKey: ["Matiere"]});
        toast.success("enregistrer");
      } else {
        toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
    });
    const addMatiere = (data) => {
    add.mutate(data);
    };

    // fin

    // suppression

    const del = useMutation({
        mutationFn: (data) => {
        return ancienMatiere.deleteMatiere(data)
        .then(res => {
            if(res.data.etat!==true){
              toast.error(res.data.message);
            } 
          })
        },
        onError: (error) => {
        toast.error("Une erreur est survenue",error);
        },
        onSuccess: () => {
        useQ.invalidateQueries({queryKey: ["Matiere"]});
        // toast.success("formations supprimée avec succès");
        },
    });

    const deleteMatiere = (data) => {
    del.mutate(data);
    };

    // fin

    // modification

    const modif = useMutation({
        mutationFn: (data) => {
        return ancienMatiere.updateMatiere(data)
        .then(res => {
            if(res.data.etat===true){
              toast.success("Modification reuissi");
              navigate(-1)
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },
        // onSuccess: () => {
        // useQ.invalidateQueries("Type");
        // toast.success("Type supprimée avec succès");
        // },
    });
    const updateMatiere = (data) => {
    modif.mutate(data);
    };

    // fin
    
      
    return {matiere, unMatiere, setMatiere, addMatiere, deleteMatiere, updateMatiere, isLoading};
  }
export function useAnc_Document(slug) {
    const useQ = useQueryClient();
    let navigate = useNavigate()
    //   pour la recuperation de tous les types
    const {
    data: documents,
    error,
    isLoading,
    } = useQuery({
    queryKey: ["Document"],
    queryFn: () =>
    ancienDocument.tousDocument()
    .then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
    });

    
    // const {
    // data: document,    
    // } = useQuery({
    // queryKey: ["DocumentUn", slug],
    // queryFn: () =>
    
    // ancienDocument.getDocument(slug)
    //   .then((res) => res.data.donnee),
    // onerror: (error) => console.log(error),
    // });

    
    const [document, setTyp] = useState([])
    const flag = useRef(false)
    useEffect(()=>{
       
        if(flag.current===false){
          ancienDocument.allDocument(slug)
          .then(res => {
            if(res.data.etat===true){
              setTyp(res.data.donnee);
            } else {
              // toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        }
        return () => flag.current = true;;;
        
    },[slug]);

    const [unDocument, setDocument] = useState([])
    const {
      // data: categorie,
      
  } = useQuery({
    queryKey: ["categorie", slug],
    queryFn: () =>
      ancienDocument.getDocument(slug)
    .then(res => {
      if(res.data.etat===true){
        setDocument(res.data.donnee);
          
      } else {
          toast.error("Les identifiants sont incorrects");
      }
  }),
    onerror: (error) => console.log(error),
  });
      
    // fin

    // pour l'ajout

    const add = useMutation({
    mutationFn: (data) => {
    return ancienDocument.addDocument(data)
    .then(res => {
      if(res.data.etat===true){
        useQ.invalidateQueries({queryKey: ["Document"]});
        toast.success("enregistrer");
      } else {
        toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
    });
    const addDocument = (data) => {
    add.mutate(data);
    };

    // fin

    // suppression

    const del = useMutation({
        mutationFn: (data) => {
        return ancienDocument.deleteDocument(data)
        .then(res => {
            if(res.data.etat!==true){
              toast.error(res.data.message);
            } 
          })
        },
        onError: (error) => {
        toast.error("Une erreur est survenue",error);
        },
        onSuccess: () => {
        useQ.invalidateQueries({queryKey: ["Document"]});
        // toast.success("formations supprimée avec succès");
        },
    });

    const deleteDocument = (data) => {
    del.mutate(data);
    };

    // fin

    // modification

    const modif = useMutation({
        mutationFn: (data) => {
        return ancienDocument.updateDocument(data)
        .then(res => {
            if(res.data.etat===true){
              toast.success("Modification reuissi");
              navigate(-1)
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },
        // onSuccess: () => {
        // useQ.invalidateQueries("Type");
        // toast.success("Type supprimée avec succès");
        // },
    });
    const updateDocument = (data) => {
    modif.mutate(data);
    };

    // fin
    
      
    return {documents, unDocument, setDocument, document, addDocument, deleteDocument, updateDocument, isLoading};
  }
export function useAnc_Pays(slug) {
    const useQ = useQueryClient();
    let navigate = useNavigate()
    //   pour la recuperation de tous les types
    const {
    data: pays,
    error,
    isLoading,
    } = useQuery({
    queryKey: ["Pay"],
    queryFn: () =>
    ancienPay.allPay()
    .then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
    });

    const [Ty, setTyp] = useState([])
    const flag = useRef(false)
    useEffect(()=>{
       
        if(flag.current===false){
            ancienType.getType(slug)
          .then(res => {
            if(res.data.etat===true){
              setTyp(res.data.donnee);
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        }
        return () => flag.current = true;;;
        
    },[slug]);
      
    // fin

    // pour l'ajout

    const add = useMutation({
    mutationFn: (data) => {
    return ancienPay.addPay(data)
    .then(res => {
      if(res.data.etat===true){
        useQ.invalidateQueries({queryKey: ["Pay"]});
        toast.success("enregistrer");
      } else {
        toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
    });
    const addPay = (data) => {
    add.mutate(data);
    };

    // fin

    // suppression

    const del = useMutation({
        mutationFn: (data) => {
        return ancienPay.deletePay(data)
        .then(res => {
            if(res.data.etat!==true){
              toast.error(res.data.message);
            } 
          })
        },
        onError: (error) => {
        toast.error("Une erreur est survenue",error);
        },
        onSuccess: () => {
        useQ.invalidateQueries({queryKey: ["Pay"]});
        // toast.success("formations supprimée avec succès");
        },
    });

    const deletePay = (data) => {
    del.mutate(data);
    };

    // fin

    // modification

    const modif = useMutation({
        mutationFn: (data) => {
        return ancienPay.updatePay(data)
        .then(res => {
            if(res.data.etat===true){
              toast.success("Modification reuissi");
              navigate(-1)
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },
        // onSuccess: () => {
        // useQ.invalidateQueries("Type");
        // toast.success("Type supprimée avec succès");
        // },
    });
    const updatePay = (data) => {
    modif.mutate(data);
    };

    // fin
    
      
    return {pays, Ty, addPay, deletePay, updatePay, isLoading};
  }
export function useAnc_PaieDoc(slug) {
    const useQ = useQueryClient();
    let navigate = useNavigate()
    //   pour la recuperation de tous les gens qui ont payer
    const {
    data: payer,
    error,
    isLoading,
    } = useQuery({
    queryKey: ["Payer", slug],
    queryFn: () =>
    documentPaiement.allDocuPaiement(slug)
    .then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
    });
      
    // fin

    // pour l'ajout

    const add = useMutation({
    mutationFn: (data) => {
    return documentPaiement.payerDocument(data)
    .then(res => {
      if(res.data.etat===true){
        useQ.invalidateQueries({queryKey: ["Payer"]});
        toast.success("enregistrer");
      } else {
        toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
    });
    const addPay = (data) => {
    add.mutate(data);
    };

    // fin

    // Verification

    const modif = useMutation({
        mutationFn: (data) => {
        return documentPaiement.verifierDocuPaiement(data)
        .then(res => {
            if(res.data.etat===true){
              toast.success("Modification reuissi");
              navigate(-1)
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },
        // onSuccess: () => {
        // useQ.invalidateQueries("Type");
        // toast.success("Type supprimée avec succès");
        // },
    });
    const verifierPay = (data) => {
    modif.mutate(data);
    };

    // fin
    
      
    return {payer, addPay, verifierPay, isLoading};
  }

export function useAnc_PaieForm(slug) {
    const useQ = useQueryClient();
    let navigate = useNavigate()
    //   pour la recuperation de tous les gens qui ont payer
    const {
    data: payer,
    error,
    isLoading,
    } = useQuery({
    queryKey: ["PayerFormation", slug],
    queryFn: () =>
    formationPaiement.allPaiement(slug)
    .then((res) => res.data.donnee),
    onerror: (error) => console.log(error),
    });
      
    // fin

    // pour l'ajout

    const add = useMutation({
    mutationFn: (data) => {
    return formationPaiement.payerFormation(data)
    .then(res => {
      if(res.data.etat===true){
        useQ.invalidateQueries({queryKey: ["PayerFormation"]});
        toast.success("enregistrer");
      } else {
        toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
    },
    onError: (error) => {
      toast.error("Une erreur est survenue0");
    },
    });
    const addPay = (data) => {
    add.mutate(data);
    };

    // fin

    // Verification

    const modif = useMutation({
        mutationFn: (data) => {
        return formationPaiement.verifierPaiement(data)
        .then(res => {
            if(res.data.etat===true){
              toast.success("Modification reuissi");
              navigate(-1)
            } else {
              toast.error("Nom trouver");
            }
          })
          .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },
        // onSuccess: () => {
        // useQ.invalidateQueries("Type");
        // toast.success("Type supprimée avec succès");
        // },
    });
    const verifierPay = (data) => {
    modif.mutate(data);
    };

    // fin
    
      
    return {payer, addPay, verifierPay, isLoading};
  }

export function useBibliotheque_Cat(slug) {
  const useQ = useQueryClient();
  let navigate = useNavigate()
  //   pour la recuperation de tous les types
  const {
  data: Categorie,
  error,
  isLoading,
  } = useQuery({
  queryKey: ["Bibliotheque_Cat", slug],
  queryFn: () =>
  bibliothequeCategorie.getCat(slug)
      .then((res) => res.data.donnee),
  onerror: (error) => console.log(error),
  });
    
  // fin
  
  const [unCategorie, setCategorie] = useState([]);
    
    const {
      // data: categorie,
      
  } = useQuery({
    queryKey: ["formations", slug],
    queryFn: () =>
      bibliothequeCategorie.unCat(slug)
    .then(res => {
      if(res.data.etat===true){
        setCategorie(res.data.donnee);
          
      } else {
          // toast.error("Les identifiants sont incorrects");
      }
  }),
    onerror: (error) => console.log(error),
  });

  // pour l'ajout

  const add = useMutation({
  mutationFn: (data) => {
  return bibliothequeCategorie.addCat(data)
  .then(res => {
      if(res.data.etat===true){
          useQ.invalidateQueries({queryKey: ["Bibliotheque_Cat"]});
          toast.success("enregistrer");
      } else {
          toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
  },
  onError: (error) => {
  toast.error("Une erreur est survenue0");
  },
  });
  const addCat = (data) => {
  add.mutate(data);
  };

  // fin

  // suppression

  const del = useMutation({
      mutationFn: (data) => {
      return bibliothequeCategorie.deleteCat(data)
      .then(res => {
          if(res.data.etat!==true){
            toast.error(res.data.message);
          } 
        })
      },
      onError: (error) => {
      toast.error("Une erreur est survenue",error);
      },
      onSuccess: () => {
      useQ.invalidateQueries({queryKey: ["Bibliotheque_Cat"]});
      // toast.success("formations supprimée avec succès");
      },
  });

  const deleteCat = (data) => {
  del.mutate(data);
  };

  // fin

  // modification

  const modif = useMutation({
      mutationFn: (data) => {
      return bibliothequeCategorie.updateCat(data)
      .then(res => {
          if(res.data.etat===true){
            toast.success("Modification reuissi");
            navigate(-1)
            useQ.invalidateQueries({queryKey: ["Bibliotheque_Cat"]});
          
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch(err => console.log(err))
      },
      onError: (error) => {
      toast.error("Une erreur est survenue0");
      },
      // onSuccess: () => {
      // useQ.invalidateQueries("Type");
      // toast.success("Type supprimée avec succès");
      // },
  });
  const updateCat = (data) => {
  modif.mutate(data);
  };

  // fin
  
    
  return {Categorie, addCat, deleteCat, updateCat, unCategorie, setCategorie, isLoading};
}

export function useBibliotheque_Aut(slug) {
  const useQ = useQueryClient();
  let navigate = useNavigate()
  //   pour la recuperation de tous les types
  const {
  data: Auteur,
  error,
  isLoading,
  } = useQuery({
  queryKey: ["Bibliotheque_Aut", slug],
  queryFn: () =>
  bibliothequeAuteur.getAut(slug)
      .then((res) => res.data.donnee),
  onerror: (error) => console.log(error),
  });
    
  // fin

  const [unAuteur, setAuteur] = useState([]);
    
    const {
      // data: categorie,
      
  } = useQuery({
    queryKey: ["formations", slug],
    queryFn: () =>
      bibliothequeAuteur.unAut(slug)
    .then(res => {
      if(res.data.etat===true){
        setAuteur(res.data.donnee);
          
      } else {
          // toast.error("Les identifiants sont incorrects");
      }
  }),
    onerror: (error) => console.log(error),
  });

  // pour l'ajout

  const add = useMutation({
  mutationFn: (data) => {
  return bibliothequeAuteur.addAut(data)
  .then(res => {
      if(res.data.etat===true){
          useQ.invalidateQueries({queryKey: ["Bibliotheque_Aut"]});
          toast.success("enregistrer");
      } else {
          toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
  },
  onError: (error) => {
  toast.error("Une erreur est survenue0");
  },
  });
  const addAut = (data) => {
  add.mutate(data);
  };

  // fin

  // suppression

  const del = useMutation({
      mutationFn: (data) => {
      return bibliothequeAuteur.deleteAut(data)
      .then(res => {
          if(res.data.etat!==true){
            toast.error(res.data.message);
          } 
        })
      },
      onError: (error) => {
      toast.error("Une erreur est survenue",error);
      },
      onSuccess: () => {
      useQ.invalidateQueries({queryKey: ["Bibliotheque_Aut"]});
      // toast.success("formations supprimée avec succès");
      },
  });

  const deleteAut = (data) => {
  del.mutate(data);
  };

  // fin

  // modification

  const modif = useMutation({
      mutationFn: (data) => {
      return bibliothequeAuteur.updateAut(data)
      .then(res => {
          if(res.data.etat===true){
            toast.success("Modification reuissi");
            navigate(-1)
            useQ.invalidateQueries({queryKey: ["Bibliotheque_Aut"]});
          
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch(err => console.log(err))
      },
      onError: (error) => {
      toast.error("Une erreur est survenue0");
      },
      // onSuccess: () => {
      // useQ.invalidateQueries("Type");
      // toast.success("Type supprimée avec succès");
      // },
  });
  const updateAut = (data) => {
  modif.mutate(data);
  };

  // fin
  
    
  return {Auteur, addAut, deleteAut, unAuteur, setAuteur, updateAut, isLoading};
}

export function useBibliotheque_Livre(slug) {
  const useQ = useQueryClient();
  let navigate = useNavigate()

  //   pour la recuperation de tous les types

  const {
  data: Livre,
  error,
  isLoading,
  } = useQuery({
  queryKey: ["Bibliotheque_Livre", slug],
  queryFn: () =>
    
  bibliothequeLivre.getLivre(slug)
      .then((res) => res.data.donnee),
  onerror: (error) => console.log(error),
  });
    
  // fin

  const [unLivre, setLivre] = useState([]);
    
    const {
      // data: categorie,
      
  } = useQuery({
    queryKey: ["formations", slug],
    queryFn: () =>
      bibliothequeLivre.unLivre(slug)
    .then(res => {
      if(res.data.etat===true){
        setLivre(res.data.donnee);
          
      } else {
          // toast.error("Les identifiants sont incorrects");
      }
  }),
    onerror: (error) => console.log(error),
  });

  // pour l'ajout

  const add = useMutation({
  mutationFn: (data) => {
  return bibliothequeLivre.addLivre(data)
  .then(res => {
      if(res.data.etat===true){
          useQ.invalidateQueries({queryKey: ["Bibliotheque_Livre"]});
          toast.success("enregistrer");
      } else {
          toast.error("Nom trouver");
      }
      })
      .catch(err => console.log(err))
  },
  onError: (error) => {
  toast.error("Une erreur est survenue0");
  },
  });
  const addLivre = (data) => {
  add.mutate(data);
  };

  // fin

  // suppression

  const del = useMutation({
      mutationFn: (data) => {
      return bibliothequeLivre.deleteLivre(data)
      .then(res => {
          if(res.data.etat!==true){
            toast.error(res.data.message);
          } 
        })
      },
      onError: (error) => {
      toast.error("Une erreur est survenue",error);
      },
      onSuccess: () => {
      useQ.invalidateQueries({queryKey: ["Bibliotheque_Livre"]});
      // toast.success("formations supprimée avec succès");
      },
  });

  const deleteLivre = (data) => {
  del.mutate(data);
  };

  // fin

  // modification

  const modif = useMutation({
      mutationFn: (data) => {
      return bibliothequeLivre.updateLivre(data)
      .then(res => {
          if(res.data.etat===true){
            toast.success("Modification reuissi");
            navigate(-1)
            useQ.invalidateQueries({queryKey: ["Bibliotheque_Livre"]});
          
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch(err => console.log(err))
      },
      onError: (error) => {
      toast.error("Une erreur est survenue0");
      },
      // onSuccess: () => {
      // useQ.invalidateQueries("Type");
      // toast.success("Type supprimée avec succès");
      // },
  });
  const updateLivre = (data) => {
  modif.mutate(data);
  };

  // fin
  
    
  return {Livre, addLivre, deleteLivre,unLivre, setLivre, updateLivre, isLoading};
}