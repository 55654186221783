import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../../components/Public/Navbar'
import Footer from '../../components/Public/Footer'

export default function Layout() {
  
  return (
    <div>
        <Navbar />
        
        <Outlet/>

        {/* <Footer/>   */}
    </div>
  )
}
