import React from 'react'
import { BASE } from '../../../../../_services/caller.service';

export default function CardLivreCategorie({post}) {
  let url = BASE(post.image)
  return (
    <>
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-md-flex align-items-center justify-content-between bg-white pt-3 pb-3 pb-lg-5">
                  <div className="d-md-flex align-items-center text-lg-start text-center">
                    <div className="me-3 mt-n8">
                      <img
                        src={url}
                        className="avatar-xxl rounded border p-4 bg-white"
                        alt="bootstarp "
                      />
                    </div>
                    <div className="mt-3 mt-md-0">
                      <h1 className="mb-0 fw-bold me-3">
                        Les livres de : {post.nom}
                      </h1>
                    </div>
                    
                  </div>
                  {/* Dropdown */}
                  
                </div>
              </div>
    </>
  )
}
