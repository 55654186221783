import React from 'react'
import { BASE } from '../../../_services/caller.service';
import PDFViewer from '../../../components/Pdf/PDFViewer';

export default function CarDetail({post}) {
    let url = BASE(post.image);
    let doc = BASE(post.document);
    console.log('f ggh b',post);
    
  return (
    <div className="row d-flex align-items-center">
                <div className="col-xxl-5 col-xl-6 col-lg-6 col-12">
                <h1 className="display-2 fw-bold mb-3">
                    Nom du livre :
                    <u className="text-warning">
                    <span className="text-primary">{post.nom}</span>
                    </u>
                </h1>
                <p className="lead mb-4">
                <h2 className="text-info">Description</h2>
                {post.description}
                
                </p>
                {/* <ul className="list-unstyled mb-5">
                    <li className="mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        fill="var(--gk-success)"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">No credit card required</span>
                    </li>
                    <li className="mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        fill="var(--gk-success)"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">Customer service 24/7</span>
                    </li>
                    <li className="mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        fill="var(--gk-success)"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">No setup fee</span>
                    </li>
                    <li className="mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        fill="var(--gk-success)"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">Cancel any time</span>
                    </li>
                </ul> */}
                <button className="btn btn-dark btn-lg fe fe-book" data-bs-toggle="modal" data-bs-target="#newFormat">
                    Lire
                </button>
                </div>
                <div className="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6 col-12 d-lg-flex justify-content-end">
                <div className="mt-8 mt-lg-0 position-relative">
                   
                    <img
                    src={url}
                    alt="online course"
                    className="img-fluid rounded-4 w-100 z-1 position-relative"
                    />
                    
                </div>
                </div>

                {/* Modal Categorie*/}
    <div className="modal fade" id="newFormat" tabIndex={-1} role="dialog" aria-labelledby="newFormatLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title mb-0" id="newCatgoryLabel">Create New Categorie</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body container">
            <PDFViewer pdfUrl={doc} />
            </div>
            </div>
        </div>
    </div> 
            </div>
  )
}
