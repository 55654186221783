import React from 'react'
import { Link } from 'react-router-dom'
import { BASE } from '../../../_services/caller.service'
import { useAnc_Document } from '../../../components/UseContext/useAncien'
import PDFCompo from '../../Admin/Fichier/PDFCompo'

export default function DocCard({post}) {
    const {document} = useAnc_Document(post.document)
    let url = BASE(document.miniature)
    let pdf = BASE(document.document)

    const handleContextMenu = (event) => {
        event.preventDefault(); // Annuler le comportement par défaut du clic droit
      };
    
    console.log(document)
  return (
    <>
    <div class="col-xl-3 col-md-6 col-12">
                <div class="card mb-4 card-hover border">
                    <a href="#!">
                    <img src={url} alt="writing" class="img-fluid w-100 rounded-top-3" />
                    </a>
                    <div class="card-body">
                    <h4 class="mb-3">
                        <a href="#!" class="text-inherit">{document.nom}</a>
                    </h4>

                    <span>
                        <button type="button" class="btn btn-outline-secondary mx-1" data-bs-toggle="modal" data-bs-target="#pdfModal">Voir le doc</button>
                    </span>
                    
                    {/* <Link to={`/formation/ancien-sujet/${post.id}`} >
                        Enroll Today
                        <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                        </svg>
                        </span>
                    </Link> */}
                </div>
            </div>
        </div>

        {/* Pour le pdf */}
        <div className="modal fade" id="pdfModal" tabIndex={-1} role="dialog" aria-labelledby="pdfModalLabel" aria-hidden="true" onContextMenu={handleContextMenu}>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header align-items-center d-flex">
                    <h4 className="modal-title" id="paymentModalLabel">Add New Payment Method</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  {/* Modal body */}
                  <div className="modal-body">
                    <div>
                      <PDFCompo pdf={pdf} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </>
  )
}
