import React from 'react'

export default function ApChat({post}) {
    
  return (
    <>
        <div className="d-flex w-lg-40 mb-4">
            <img src="../../assets/images/avatar/avatar-4.jpg" alt className="rounded-circle avatar-md" />
            {/* media body */}
            <div className="ms-3">
            <small>sharad bb , 09:42</small>
            <div className="d-flex">
                <div className="card mt-2 rounded-top-md-left-0">
                <div className="card-body p-3">
                    <p className="mb-0 text-dark">{post.message}</p>
                </div>
                </div>
                <div className="ms-2 mt-2">
                {/* dropdown */}
                
                </div>
            </div>
            </div>
        </div>
    </>
  )
}
