import React from "react";
import NavF from "./NavF";
import { useParams } from "react-router-dom";
import { useBibliotheque_Cat } from "../../../components/UseContext/useAncien";
import LivreNav from "./LivreNav";
import CardLivreCategorie from "./Livre/Card/CardLivreCategorie";

export default function LivreCategories() {
  let { id } = useParams();
  const top = {
    id: id,
  };

  const { Categorie } = useBibliotheque_Cat(top);
  
  return (
    <>
      <div>
        {/* Bg cover */}
        <section
          className="py-8"
          style={{
            background: "linear-gradient(270deg, #9d4eff 0%, #782af4 100%)",
          }}
        />
        {/* Page header */}
        <section className="bg-white shadow-sm">
          <div className="container">
            <div className="row align-items-center">
              {Categorie?.map((post, index)=> {
                return <CardLivreCategorie key={index} post={post} />
              })}
            </div>
          </div>
        </section>
      </div>

      <section className="py-6">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Tab content */}
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-course"
                  role="tabpanel"
                  aria-labelledby="pills-course-tab"
                >
                  {/* Tab pane */}
                  {/* <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-5">
                        <h2 className="mb-1">Beginner</h2>
                        <p>
                          Learn Bootstrap tutorial for beginners with there easy
                          components and utility.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    {Categorie?.map((post) => (
                     post.livre?.map((post) => {
                      return <LivreNav livre={post} />
                     } )
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
