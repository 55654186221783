import React from 'react'
import { useAnc_Niveau } from '../../../../components/UseContext/useAncien';
import { useNavigate, useParams } from 'react-router-dom';

export default function ModifNiveau() {
    let {id} = useParams()
    const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
    
    const {unNiveau, setNiveau, updateNiveau} = useAnc_Niveau(id)

    const onChangeQcm = (e) => {
        setNiveau({
            ...unNiveau,
            [e.target.name]: e.target.value
        })
    }

    const onSubmitQcm = (e) => {
        e.preventDefault();       
    
        updateNiveau(unNiveau);
      };
  return (
    <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Ajouter Niveau</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmitQcm}>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Nom
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='nom' onChange={onChangeQcm} className="form-control" value={unNiveau.nom} required />
                
            </div>
            {/* <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Durée
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='duree' onChange={onChangeQcm} className="form-control" placeholder="Write a Category" required />
                <small>Field must contain a unique value</small>
                <div className="invalid-feedback">Please enter category.</div>
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Description
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='description' onChange={onChangeQcm} className="form-control" placeholder="Write a Category" required />
                <small>Field must contain a unique value</small>
                <div className="invalid-feedback">Please enter category.</div>
            </div> */}
            
            <div>
            <button type="submit" className="btn btn-primary">Modifier</button>
            <button type="button" className="btn btn-secondary mx-1" onClick={handleGoBack}>Retour</button>
            
            </div>
        </form>
        </div>
        </div>
  )
}
