import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import FormationContext from "../../../components/UseContext/formation.context";
import useForma, { useSousCat } from "../../../components/UseContext/useForma";
import useUtilisateur from "../../../components/UseContext/useUtilisateur";
import { BASE } from "../../../_services/caller.service";

export default function FormationEdit() {
  let { slug } = useParams();

  const { user } = useContext(FormationContext);
  const { updateForm, unFormation, setFormation } = useForma(slug);
  const onChangee = (e) => {
    setFormation({
      ...unFormation,
      [e.target.name]: e.target.value,
    });
  };
  const { sous_categories } = useSousCat();
  const top = {
    id: user,
  };
  const { user: posts } = useUtilisateur(top);

  const [ajout_terminer, setTerminer] = useState(false);

  const Ajout_Terminer = () => {
    ajout_terminer ? setTerminer(false) : setTerminer(true);
  };

  const [base64Image, setBase64Image] = useState("");

  const [yes, setYes] = useState([]);
  const onChange = (e) => {
    setYes({
      ...yes,
      [e.target.name]: e.target.value,
    });
  };
  const [prix, setPrix] = useState([]);
  const onChangePrix = (e) => {
    setPrix({
      ...prix,
      [e.target.name]: e.target.value,
    });
  };
  const [nbr, setNbr] = useState([]);
  const onChangeNbr = (e) => {
    setNbr({
      ...nbr,
      [e.target.name]: e.target.value,
    });
  };
  const [pre, setPre] = useState([]);
  const onChangePre = (e) => {
    setPre({
      ...pre,
      [e.target.name]: e.target.value,
    });
  };
  const [desc, setDesc] = useState([]);
  const onChangeDesc = (e) => {
    setDesc({
      ...desc,
      [e.target.name]: e.target.value,
    });
  };
  const [pro, setPro] = useState([]);
  const onChangePro = (e) => {
    setPro({
      ...pro,
      [e.target.name]: e.target.value,
    });
  };
  const [obj, setObj] = useState([]);
  const onChangeObj = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };
  const [scat, setScat] = useState([]);
  const onChangeScat = (e) => {
    setScat({
      ...scat,
      [e.target.name]: e.target.value,
    });
  };
  const [pho, setPho] = useState([]);
  const onChangeSt = (e) => {
    setPho({
      ...pho,
      [e.target.name]: e.target.value,
    });
  };
  // fin

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Convertir l'image en base64
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onSubmitAjout_T = (e) => {
    e.preventDefault();

    const data = {
      slug: slug,
      ajout_terminer: ajout_terminer,
    };

    updateForm(data);
  };
  const onSubmitNom = (e) => {
    e.preventDefault();

    yes["slug"] = slug;

    updateForm(yes);
  };
  const onSubmitDesc = (e) => {
    e.preventDefault();

    // console.log("description ", desc)
    desc["slug"] = slug;

    updateForm(desc);
  };
  const onSubmitN_H = (e) => {
    e.preventDefault();

    // console.log("nombre_heure ", nbr)
    nbr["slug"] = slug;

    updateForm(nbr);
  };
  const onSubmitObj = (e) => {
    e.preventDefault();

    obj["slug"] = slug;

    updateForm(obj);
  };
  const onSubmitPrere = (e) => {
    e.preventDefault();

    pre["id"] = slug;

    updateForm(pre);
  };
  const onSubmitPhoto = (e) => {
    e.preventDefault();
    const pho = {};

    if (base64Image.includes("base64")) {
      pho["miniature"] = base64Image;
      pho["instructeur_id"] = user;
      pho["slug"] = slug;
    } else {
      delete pho["miniature"];
    }

    updateForm(pho);
  };
  const onSubmitPrix = (e) => {
    e.preventDefault();

    // console.log("prix ", prix)
    prix["slug"] = slug;

    updateForm(prix);
  };
  const onSubmitSousCat = (e) => {
    e.preventDefault();

    // console.log("SousCate ", scat)
    scat["slug"] = slug;
    updateForm(scat);
  };
  const onSubmitPro_De = (e) => {
    e.preventDefault();
    pro["slug"] = slug;
    updateForm(pro);
  };

  const onSubmitEdit = (e) => {
    e.preventDefault();

    unFormation["slug"] = slug;
    unFormation["ajout_terminer"] = ajout_terminer;

    if (base64Image.includes("base64")) {
      unFormation["miniature"] = base64Image;
      unFormation["instructeur_id"] = user;
      unFormation["slug"] = slug;
    } else {
      delete unFormation["miniature"];
    }
    console.log("kk kk ..", unFormation);
    updateForm(unFormation)
  };
  let url = BASE(unFormation.miniature);

  return (
    <>
      <section className="container-fluid p-4">
        {posts?.map((post) => (
          <form onSubmit={onSubmitEdit}>
            <div className="row g-3">
              <div className="col-md-4">
                <label className="form-label">Nom</label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newNomCatgory"
                >
                  Success
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="nom"
                  onChange={onChangee}
                  value={unFormation.nom}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="validationDefault02" className="form-label">
                  Prix
                </label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newPrixCatgory"
                >
                  Success
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="prix"
                  onChange={onChangee}
                  value={unFormation.prix}
                />
              </div>
              {/* <div className="col-md-4">
    <label htmlFor="validationDefaultUsername" className="form-label">Username</label>
    <div className="input-group">
      <span className="input-group-text" id="inputGroupPrepend2">@</span>
      <input type="text" className="form-control" value={post.username} disabled />
    </div>
  </div> */}
              <div className="col-md-6">
                <label htmlFor="validationDefault03" className="form-label">
                  Nombre heure
                </label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newNomHeuCatgory"
                >
                  Success
                </span>
                <input
                  type="number"
                  className="form-control"
                  name="nombre_heur"
                  onChange={onChangee}
                  placeholder={unFormation.nombre_heur}
                  required
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationDefault04" className="form-label">
                  Sous Categorie
                </label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newSoCatCatgory"
                >
                  Success
                </span>
                <select className="form-select" disabled>
                  <option selected disabled>
                    {unFormation.sous_categorie_slug}
                  </option>

                  {sous_categories.map((post) => (
                    <option value={post.slug}>{post.nom}</option>
                  ))}
                </select>
              </div>
              <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                  Image miniature
                  <span className="text-danger">*</span>
                  <span
                    class="badge text-bg-success"
                    data-bs-toggle="modal"
                    data-bs-target="#newMinCatgory"
                  >
                    Modifier.
                  </span>
                </label>
                <div>
                  <img src={url} alt="course" className="rounded img-4by3-lg" />
                </div>
              </div>
              <div className="col-md-10">
                <label htmlFor="validationDefault05" className="form-label">
                  Description
                </label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newDesCatgory"
                >
                  Success
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  onChange={onChangee}
                  value={unFormation.description}
                />
              </div>
              <div className="col-md-10">
                <label htmlFor="validationDefault05" className="form-label">
                  Prerequis
                </label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newPreCatgory"
                >
                  Success
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="prerequis"
                  onChange={onChangee}
                  value={unFormation.prerequis}
                />
              </div>
              <div className="col-md-10">
                <label htmlFor="validationDefault05" className="form-label">
                  Profile destine
                </label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newPrDeCatgory"
                >
                  Success
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="profile_destine"
                  onChange={onChangee}
                  value={unFormation.profile_destine}
                />
              </div>
              <div className="col-md-10">
                <label htmlFor="validationDefault05" className="form-label">
                  Objetif du cours
                </label>
                <span
                  class="badge text-bg-success"
                  data-bs-toggle="modal"
                  data-bs-target="#newObCatgory"
                >
                  Success
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="objectif_du_cours"
                  onChange={onChangee}
                  value={unFormation.objecti_du_cours}
                />
              </div>

              {post.type_compte === "instructeur" && (
                <div className="col-12">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      onClick={Ajout_Terminer}
                    />
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckChecked"
                    >
                      Ajout terminer{" "}
                      {ajout_terminer
                        ? ajout_terminer.toString()
                        : ajout_terminer.toString()}{" "}
                    </label>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary">
                      Ajouter
                    </button>
                  </div>
                </div>
              )}

              {/* <div className="col-12">
    <button className="btn btn-primary" type="submit">Submit form</button>
  </div> */}
            </div>
          </form>
        ))}
      </section>

      {/* Modal */}

      {/* Nom */}
      <div
        className="modal fade"
        id="newNomCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newNomCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitNom}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Nom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="nom"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Nom ..."
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Prix */}
      <div
        className="modal fade"
        id="newPrixCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newPrixCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitPrix}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Prix
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="prix"
                    onChange={onChangePrix}
                    className="form-control"
                    placeholder="Prix ..."
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Nombre Heure */}
      <div
        className="modal fade"
        id="newNomHeuCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newNomHeuCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitN_H}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Nombre Heure
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="nombre_heur"
                    onChange={onChangeNbr}
                    className="form-control"
                    placeholder="Nombre d'heure"
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Prerequis */}
      <div
        className="modal fade"
        id="newPreCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newPreCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitPrere}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Prerequis
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="prerequis"
                    onChange={onChangePre}
                    className="form-control"
                    placeholder="Prerequis ..."
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Description */}
      <div
        className="modal fade"
        id="newDesCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newDesCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitDesc}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Description
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="description"
                    onChange={onChangeDesc}
                    className="form-control"
                    placeholder="Description"
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Profil Destiner */}
      <div
        className="modal fade"
        id="newPrDeCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newPrDeCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitPro_De}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Profil Destiner
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="profile_destine"
                    onChange={onChangePro}
                    className="form-control"
                    placeholder="Profil Destiner ..."
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Objectif */}
      <div
        className="modal fade"
        id="newObCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newObCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitObj}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Objectif du cours
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="objectif_du_cours"
                    onChange={onChangeObj}
                    className="form-control"
                    placeholder="Objectif ..."
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Miniature Photo */}
      <div
        className="modal fade"
        id="newMinCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newMinCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitPhoto}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Image miniature
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    placeholder="Image"
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* SOus Categorie */}
      <div
        className="modal fade"
        id="newSoCatCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newMinCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Modifier
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmitSousCat}>
                <div className="mb-3 mb-2">
                  <select
                    class="form-select"
                    name="sous_categorie_slug"
                    onChange={onChangeScat}
                  >
                    <option selected>SousCat</option>
                    <option>..</option>
                    {sous_categories.map((post) => (
                      <option value={post.slug}>{post.nom}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
