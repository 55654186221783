import React from 'react'
import { BASE } from '../../../_services/caller.service';
import { useNavigate } from 'react-router-dom';

export default function SousModifCard({handleFileChange, onChange, onSubmit, nom}) {
    let url = BASE(nom.image);
    const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
    <div className="modal-body">
            <form className="needs-validation" onSubmit={onSubmit}>
              <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                  Nom
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  type="text"
                  name="nom"
                  onChange={onChange}
                  
                  className="form-control"
                  value={nom.nom}
                  required
                />
                {/* <small>Field must contain a unique value</small>
                  <div className="invalid-feedback">Please enter category.</div> */}
              </div>
              <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                  Image de la sous-categorie
                </label>
                <div>
                  <img src={url} alt="course" className="rounded img-4by3-lg" />
                </div>
              </div>
              <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                  Image
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="form-control"
                />
                {/* <small>Field must contain a unique value</small>
                  <div className="invalid-feedback">Please enter category.</div> */}
              </div>

              <div>
                <button type="submit" className="btn btn-primary">
                  Add New Sous-Categorie
                </button>
                <button type="button" className="btn btn-secondary mx-1" onClick={handleGoBack}>Retour</button>
                
              </div>
            </form>
          </div>
    </>
  )
}
