import React from 'react'
import { useParams } from 'react-router-dom'
import FormaQCM from '../Qcm/FormaQCM';

import AjoutChapitre from '../Chapitre/AjoutChapitre';

import { useQuery } from '@tanstack/react-query';
import { courService } from '../../../_services';
import CourFormat from '../Formation_Cours/CourFormat';
import { useCour } from '../../../components/UseContext/useForma';



export default function FormQcmChapit() {
  
    let {slug} = useParams()

    const top = {
      formation_slug : slug,
    }
    const {cours} = useCour(top)
  
  return (
    <>

      <div className="col-lg-4 col-md-12 col-12">
        {/* Card */}
        <div className="card mb-4">
          <div className="p-4">
            <span className="fs-6 text-uppercase fw-semibold">Le nombre de personne qui ont acheter ce cours.</span>
            <h2 className="mt-4 fw-bold mb-1 d-flex align-items-center h1 lh-1">{cours?.length}</h2>
            {/* <span className="d-flex justify-content-between align-items-center">
              <span>New this month</span>
              <span className="badge bg-info ms-2">120+</span>
            </span> */}
          </div>
        </div>
      </div>
    {/* Chapitre */}
    <AjoutChapitre slug={slug}/>

    {/* QCM */}
    <FormaQCM slug={slug} />

    {/* Cour */}
    <CourFormat slug={slug} />

    </>
  )
}
