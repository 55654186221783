import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error from '../../_utils/Error';
import Layout from './Layout';
import Accueil from './Accueil';
import FormationDetail from '../Instructeur/Formation/FormationDetail';
import VideoVue from './VueVideo/VideoVue';
import VideoApp from '../../components/VideoApp';
import NavCard from '../../components/Public/NavCard';
import Categorie from './Categorie/Categorie';
import ListDocument from '../Admin/Ancien_Sujet/Document/ListDocument';
import Detail from '../Admin/Ancien_Sujet/Document/Detail';
import NavBiblio from '../Admin/Bibliotheque_Numerique/NavBiblio';
import DetailLivre from '../Admin/Bibliotheque_Numerique/DetailLivre';
import LivreCategories from '../Admin/Bibliotheque_Numerique/LivreCategories';


export default function PublicRoute() {

  return (
    
    <Routes>
        <Route element={<Layout/>}>
          <Route index element={<Accueil />} />

          <Route path='formation'>
            
            <Route path='categorie' >
              <Route index element={<Categorie />} />
              <Route path=':slug' element={<NavCard />} />
            </Route>
            
            <Route path='ancien-sujet' >
              <Route path='type/:id' element={<ListDocument />} />
              <Route path=':id' element={<Detail />} />
            </Route>

            <Route path='detail' >
              <Route path=':slug' element={<FormationDetail />} />
            </Route>

            <Route path='bibliotheque' >
              <Route path='categorie' element={<NavBiblio />} />
              <Route path='categorie/:id' element={<LivreCategories />} />
              <Route path='categorie/detail/:slug' element={<DetailLivre />} />
            </Route>
            
            <Route path='video'>
              <Route path=':slug' element={<VideoVue />} />

            </Route>
            <Route path='test' element={<VideoApp />} />
          </Route>

          <Route path='*' element={<Error/> }/>
        </Route>
    </Routes>
    
  );
}
