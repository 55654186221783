import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useAnc_Type, { useBibliotheque_Aut, useBibliotheque_Cat, useBibliotheque_Livre } from '../../../../components/UseContext/useAncien';
import useUtilisateur from '../../../../components/UseContext/useUtilisateur';

export default function LivreModif() {
    let {id} = useParams()

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };
    
    const [base64Image, setBase64Image] = useState('');
    const [base64Doc, setBase64Doc] = useState('');
    const top = {
        slug: id
    }
    const all = {
        all: "all"
    }
    const [type, setType] = useState([])
    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        // Convertir l'image en base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
    
        reader.readAsDataURL(file);
    };
    const DocumentChange = (e) => {
        const file = e.target.files[0];
    
        // Convertir l'image en base64
        const reader = new FileReader();
        reader.onloadend = () => {
            setBase64Doc(reader.result);
        };
    
        reader.readAsDataURL(file);
    };

    const {Auteur} = useBibliotheque_Aut(all)
    const {Categorie} = useBibliotheque_Cat(all)
    const {connect} = useUtilisateur()
    const {unLivre, setLivre, updateLivre} = useBibliotheque_Livre(id) 
    const { unCategorie} = useBibliotheque_Cat(unLivre.categorie)
    const {unAuteur} = useBibliotheque_Aut(unLivre.auteur)
    
    const onChangeQcm = (e) => {
        setLivre({
            ...unLivre,
            [e.target.name]: e.target.value
        })
    }
    
    const onSubmitQcm = (e) => {
        e.preventDefault(); 

        if(base64Image.includes("base64"))
        {
          unLivre["image"] = base64Image
        }else{
          delete unLivre["image"]
        }

        if(base64Doc.includes("base64"))
        {
            unLivre['document'] = base64Doc
        }else{
        delete unLivre['document']
        }

        unLivre["admin_id"] = connect
        unLivre["id"]=id
        console.log("unLivre", unLivre)
        updateLivre(unLivre);
        
      };
  return (
    <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Ajout des livres</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmitQcm}>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Nom
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='nom' onChange={onChangeQcm} className="form-control" value={unLivre.nom} />
                
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Reference
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='reference' onChange={onChangeQcm} className="form-control" value={unLivre.reference} />
                
            </div>
            
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Image
                    
                </label>
                <input type="file" onChange={handleFileChange} className="form-control" />
                
            </div>

            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Document
                    
                </label>
                <input type="file" onChange={DocumentChange} className="form-control" />
                
            </div>

            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Description
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='description' onChange={onChangeQcm} className="form-control" value={unLivre.description} />
                
            </div>

            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Choisissez la categorie
                    
                </label>
                <select class="form-select " name='categorie_id' onChange={onChangeQcm} aria-label="Default select example">
                    <option value={unLivre.categorie} selected>{unCategorie.nom}</option>
                    {Categorie?.length > 0 ? 
                    Categorie.map((post) => (
                        <option value={post.id}>{post.nom}</option>
                    ))
                    : 'Pas de categorie'
                    } 
                
                </select>
            </div>

            <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
                Choisissez l'auteur
                
            </label>
            <select class="form-select " name='auteur_id' onChange={onChangeQcm} aria-label="Default select example">
                <option value={unLivre.auteur} selected>{unAuteur.nom}</option>
                {Auteur?.length > 0 ? 
                Auteur.map((post) => (
                    <option value={post.id}>{post.nom}</option>
                ))
                : "Pas d'auteur"
                } 
            
            </select>
            </div>
            
            <div>
            <button type="submit" className="btn btn-primary">Modifier</button>
            <button type="button" className="btn btn-secondary mx-1" onClick={handleGoBack}>Retour</button>
            
            </div>
        </form>
        </div>
    </div>
  )
}
