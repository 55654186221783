import React from "react";
import Slid from "./Test/Slid";
import { Carousel } from "react-responsive-carousel";
import { useSlider } from "../UseContext/useForma";
import SliderImg from "./SliderImg";

export default function Slider() {
  const { sliders: slides, isLoading } = useSlider();

  if (isLoading) {
    return <div>Chargement...</div>;
  }
  return (
    <>
      <Carousel
        autoPlay
        infiniteLoop
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        thumbWidth={553 * 408}
        showThumbs={false}
      >
        {slides?.map((formation, index) => (
          <section class="bg-primary" key={index}>
            <div class="container">
              <div class="row align-items-center g-0">
                <div class="col-xl-5 col-lg-6 col-md-12">
                  <div class="py-7 py-lg-0">
                    <h1 class="text-white display-4 fw-bold">
                      {formation.nom}
                    </h1>
                    <p class="text-white-50 mb-4 lead">
                      {formation.description}
                    </p>
                    {/* <a href="pages/course-filter-list.html" class="btn btn-dark">Browse Courses</a>
                                <a href="pages/sign-in.html" class="btn btn-white">Are You Instructor?</a> */}
                  </div>
                </div>
                <SliderImg formation={formation} />
              </div>
            </div>
          </section>
        ))}
      </Carousel>
    </>
  );
}
