import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import FormationContext from "../UseContext/formation.context";
import { useSousCat, useSuive } from "../UseContext/useForma";
import CardNav from "./CardNav";

export default function NavCard() {
  let { slug } = useParams();

  const { user } = useContext(FormationContext);
  const { addsuive } = useSuive();
  const top = {
    id: slug,
  };
  const { sous_categorie: posts, isLoading } = useSousCat(top);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  const onSubmitCour = (e) => {
    e.preventDefault();

    const data = {
      souscategorie_id: slug,
      apprenant_id: user,
    };

    addsuive(data);
  };

  return (
    <>
      <main>
        {posts?.map((sous_categorie) => {
          return (
            <CardNav
              sous_categorie={sous_categorie}
              onSubmitCour={onSubmitCour}
            />
          );
        })}
      </main>
    </>
  );
}
