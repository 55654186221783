import React from "react";
import { useAnc_PaieForm } from "../../../components/UseContext/useAncien";
import AchatCard from "./AchatCard";

export default function AchatFormation() {
  const top = {
    all: "user",
  };
  const { payer } = useAnc_PaieForm(top);
  return (
    <>
      <div className="card mb-4 mx-3 my-3">
        {/* Card header */}
        <div className="card-header border-bottom-0">
          <h3 className="h4 mb-3">
            Listes des personnes qui ont acheter un document
          </h3>
          <div className="row align-items-center"></div>
        </div>
        {/* Table */}
        <div className="table-responsive">
          <table className="table mb-0 text-nowrap table-hover table-centered table-with-checkbox">
            <thead className="table-light">
              <tr>
                <th>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkAll"
                    />
                    <label className="form-check-label" htmlFor="checkAll" />
                  </div>
                </th>

                <th>Nom du document</th>
                <th>Nom de la personne</th>
                {/* <th>Année</th> */}

                <th />
              </tr>
            </thead>
            <tbody>
              {payer?.length > 0
                ? payer.map((post) => <AchatCard post={post} />)
                : "Pas de Document"}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
