import React, { useState } from "react";

import ChapitreCard from "./ChapitreCard";
import { useChapitre } from "../../../components/UseContext/useForma";

export default function AjoutChapitre({ slug }) {
  const [chap, setchap] = useState([]);
  const onChange = (e) => {
    setchap({
      ...chap,
      [e.target.name]: e.target.value,
    });
  };
  const sluger = {
    formation_slug: slug,
    all: "all",
  };

  const { chapitre: chapitres, addChapitre, isLoading } = useChapitre(sluger);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    chap["formation_slug"] = slug;

    addChapitre(chap);
  };
  return (
    <>
      <div className="card mb-4">
        {/* Card header */}
        <div className="card-header border-bottom-0">
          <h3 className="h4 mb-3">Listes des chapitres</h3>
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-6 text-lg-end">
              {/* Button */}
              <button
                className="btn btn-outline-secondary btn-icon"
                data-bs-toggle="modal"
                data-bs-target="#newCatgory"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-plus"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="table-responsive">
          <table className="table mb-0 text-nowrap table-hover table-centered table-with-checkbox">
            <thead className="table-light">
              <tr>
                <th>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkAll"
                    />
                    <label className="form-check-label" htmlFor="checkAll" />
                  </div>
                </th>
                {/* <th>ID</th> */}
                <th>Nom</th>
                

                <th />
              </tr>
            </thead>
            <tbody>
              {chapitres?.length > 0
                ? chapitres.map((post) => <ChapitreCard post={post} formation_slug={slug} />)
                : "Pas de chapitre"}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal Chapitre */}
      <div
        className="modal fade"
        id="newCatgory"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newCatgoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="newCatgoryLabel">
                Nouveau Chapitre
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" onSubmit={onSubmit}>
                <div className="mb-3 mb-2">
                  <label className="form-label" htmlFor="title">
                    Nom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="nom"
                    onChange={onChange}
                    className="form-control"
                    placeholder="Nom du chapitre"
                    required
                  />
                  
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Ajouter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
