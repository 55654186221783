import { get } from 'react-hook-form'
import Axios from './caller.service'

/**
 * Récupération d'un utilisateur
 */
let getCat = (id) => {
    
    return Axios.post(`bibliotheque-numerique/categorie/get`,id)
}

let unCat = (id) => {
    
    return Axios.get(`bibliotheque-numerique/categorie/get/${id}`)
}

/**
 * Ajout d'un utilisateur
 */
let addCat = (data) => {
    return Axios.post('bibliotheque-numerique/categorie/add', data)
}

/**
 * Mise à jour d'un utilisateur
 */
let updateCat = (nom) => {
    return Axios.post('bibliotheque-numerique/categorie/set', nom)
}

/**
 * Suppression d'un utilsateur
 */
let deleteCat = (categorie) => {
    return Axios.post(`bibliotheque-numerique/categorie/del`,categorie)
}

// Décaraltion des esrvices pour import
export const bibliothequeCategorie = {
    getCat, addCat, updateCat, deleteCat, unCat
}

/**
 * Récupération d'un utilisateur
 */
let getAut = (id) => {
    return Axios.post(`bibliotheque-numerique/auteur/get`,id)
}

let unAut = (id) => {
    return Axios.get(`bibliotheque-numerique/auteur/get/${id}`)
}

/**
 * Ajout d'un utilisateur
 */
let addAut = (data) => {
    return Axios.post('bibliotheque-numerique/auteur/add', data)
}

/**
 * Mise à jour d'un utilisateur
 */
let updateAut = (nom) => {
    return Axios.post('bibliotheque-numerique/auteur/set', nom)
}

/**
 * Suppression d'un utilsateur
 */
let deleteAut = (categorie) => {
    return Axios.post(`bibliotheque-numerique/auteur/del`,categorie)
}

// Décaraltion des esrvices pour import
export const bibliothequeAuteur = {
    getAut, addAut, updateAut, deleteAut, unAut
}

/**
 * Récupération d'un utilisateur
 */
let getLivre = (id) => {
    
    return Axios.post(`bibliotheque-numerique/livre/get`,id)
}

let unLivre = (id) => {
    
    return Axios.get(`bibliotheque-numerique/livre/get/${id}`)
}

/**
 * Ajout d'un utilisateur
 */
let addLivre = (data) => {
    return Axios.post('bibliotheque-numerique/livre/add', data)
}

/**
 * Mise à jour d'un utilisateur
 */
let updateLivre = (nom) => {
    return Axios.post('bibliotheque-numerique/livre/set', nom)
}

/**
 * Suppression d'un utilsateur
 */
let deleteLivre = (categorie) => {
    return Axios.post(`bibliotheque-numerique/livre/del`,categorie)
}

// Décaraltion des esrvices pour import
export const bibliothequeLivre = {
    getLivre, addLivre, updateLivre, deleteLivre, unLivre
}
