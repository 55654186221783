import React, { useEffect, useRef, useState } from 'react'
import { accountService, temoinService } from '../../_services';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

export default function useUtilisateur(slug) {
  const [user, setPost] = useState([]);
  const connect = accountService.getToken()
  const flag = useRef(false)
  const useQ = useQueryClient();
  
  // pour l'affichage
  // const {
  //   data: user,
  //   error,
  //   isLoading,
  // } = useQuery({
  //   queryKey: ["userr", slug],
  //   queryFn: () =>
  //   accountService.allUsers(slug)
  //   .then(res => res.data.donnee),
  //   onerror: (error) => console.log(error),
  // });
  
  
  useEffect(()=>{

    if(flag.current===false){
      accountService.allUsers(slug)
      .then(res => {
        if(res.data.etat===true){
          setPost(res.data.donnee);
        } else {
          // toast.error("Nom trouver");
        }
      })
      .catch(err => console.log())
    }
    return () => flag.current = true;;;
      
  },[slug]);

  const [unUser, setUser] = useState([]);
  
  const {
    // data: categorie,
  } = useQuery({
    queryKey: ["formations", slug],
    queryFn: () =>
      accountService.unUser(slug).then((res) => {
        if (res.data.etat === true) {
          console.log("edit", res.data.donnee)
          setUser(res.data.donnee);
          toast.success("Connexion réussie");
        } else {
          // toast.error("Les identifiants sont incorrects");
        }
      }),
    onerror: (error) => console.log(error),
  });
  
  // fin
  // pour la suppression
  const del = useMutation({
    mutationFn: (post) => {
    return accountService.deleteDiscution(post)
    .then(res => {
        if(res.data.etat!==true){
          toast.error(res.data.message);
        } 
      })
    },
    onError: (error) => {
    toast.error("Une erreur est survenue",error);
    },
    onSuccess: () => {
      useQ.invalidateQueries({queryKey: ["souscat"]});
    // toast.success("formations supprimée avec succès");
    },
  });
  const deleteSousCat = (post) => {
    del.mutate(post);
  };
  // fin
  // pour l'ajout
  const add = useMutation({
    mutationFn: (data) => {
    return accountService.addSuive(data)
    .then(res => {
      if(res.data.etat===true){
        useQ.invalidateQueries({queryKey: ["souscat"]});
        
      } else {
        toast.error("Nom trouver");
      }
    })
    .catch(err => console.log(err))
    },
    onError: (error) => {
    toast.error("Une erreur est survenue0");
    }
  });
  const addSousCat = (chap) => {
    add.mutate(chap);
  };
  // fin
  // pour la modification
  const modif = useMutation({
        mutationFn: (data) => {
        return accountService.updateUser(data)
        .then(res => {
          if(res.data.etat===true){
            toast.success("Modification reuissi");
            // useQ.invalidateQueries({queryKey: ["souscat"]});
            
          } else {
            toast.error("Nom trouver");
          }
        })
        .catch(err => console.log(err))
        },
        onError: (error) => {
        toast.error("Une erreur est survenue0");
        },        
  });
  const updateUser = (chap) => {
    modif.mutate(chap);
  };
  // fin

  return {user, unUser, setUser, connect, deleteSousCat, addSousCat, updateUser}
}
