import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useChapitre } from '../../../components/UseContext/useForma';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { formationChapitre } from '../../../_services';

export default function ChapitreModif() {
    let {id, slug} = useParams()
    
    const {updateChapitre} = useChapitre()
    
    const [chap, setchap] = useState([])
    const onChange = (e) => {
        setchap({
            ...chap,
            [e.target.name]: e.target.value
        })
    }
    const top = {
      id: id,
      formation_slug: slug,

    }
    // const { chapitre } = useChapitre(top);
    
    // const [nom, setNom] = useState([]);

    const {
        // data: categorie,
        error,
        isLoading,
    } = useQuery({
      queryKey: ["categorie", id],
      queryFn: () =>
        formationChapitre.getChapitre(id)
      .then(res => {
        if(res.data.etat===true){
          setchap(res.data.donnee);
            toast.success("Connexion réussie");
        } else {
            toast.error("Les identifiants sont incorrects");
        }
    }),
      onerror: (error) => console.log(error),
    });
    if (isLoading) {
      return <div>Chargement...</div>;
    }

    // const onChangee = (e) => {
    //     setNom({
    //         ...nom,
    //         [e.target.name]: e.target.value
    //     })
    // }


      const onSubmit = (e) => {
        e.preventDefault();
        
        chap["id"]= id
    
        updateChapitre(chap);
      };
  return (
    <>
    <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmit}>
          

        <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
            Nom
            <span className="text-danger">*</span>
            </label>
            <input type="text" name='nom' onChange={onChange} className="form-control" value={chap.nom} />
            
        </div>
          
        <div>
            <button type="submit" className="btn btn-primary">Modifier Chapitre</button>
            
        </div>
        </form>
    </div>
    </>
  )
}
