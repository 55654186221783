import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { accountService } from "../../_services";
import { BASE } from "../../_services/caller.service";
import useAnc_Type from "../UseContext/useAncien";
import useUtilisateur from "../UseContext/useUtilisateur";
import { useSousCat } from "../UseContext/useForma";

export default function Navbar() {
  const user = accountService.getToken();
  let navigate = useNavigate();

  const top = {
    all: "all",
  };
  const { sous_categories } = useSousCat(top);
  const { Type: documents } = useAnc_Type(top);

  const s_categories = sous_categories.slice(0, 3);

  const logout = () => {
    accountService.logout();
    toast.success("Déconneter");
    navigate("/");
  };
  const login = () => {
    navigate("/auth/login");
  };
  const connect = accountService.isLogged();

  const ID = {
    id: user,
  };

  const { user: posts } = useUtilisateur(ID);

  // fin

  let url = BASE(posts.avatar);

  // fin
  return (
    <>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg">
        {posts.length > 0 ? (
          posts?.map((post, index) => (
            <div key={index} className="container-fluid px-0">
              <a className="navbar-brand" href="/">
                <img src="../../assets/images/brand/logo/favicon.ico" alt="LonyAcademy" />
              </a>
              {/* Mobile view nav wrap */}
              <div className="ms-auto d-flex align-items-center order-lg-3">
                
                <ul className="navbar-nav navbar-right-wrap ms-2 flex-row d-md-block">
                  <li className="dropdown ms-2 d-inline-block position-static">
                    <Link to={'#'}
                      className="rounded-circle"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                    >
                      <div className="avatar avatar-md avatar-indicators avatar-online">
                        {connect ? (
                          <>
                            <img
                              alt="avatar"
                              src={url}
                              className="rounded-circle"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="avatar"
                              src="./assets/images/avatar/avatar-1.jpg"
                              className="rounded-circle"
                            />
                          </>
                        )}
                      </div>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end position-absolute mx-3 my-5">
                      <div className="dropdown-item">
                        <div className="d-flex">
                          {connect ? (
                            <>
                              <div className="avatar avatar-md avatar-indicators avatar-online">
                                <img
                                  alt="avatar"
                                  src={url}
                                  className="rounded-circle"
                                />
                              </div>
                              <div className="ms-3 lh-1">
                                <h5 className="mb-1">
                                  {post.last_name} {post.first_name}
                                </h5>
                                <p className="mb-0">{post.email}</p>
                              </div>
                            </>
                          ) : (
                            "nom"
                          )}
                        </div>
                      </div>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <li className="dropdown-submenu dropstart-lg">
                          <a
                            className="dropdown-item dropdown-list-group-item dropdown-toggle"
                            href="/admin"
                          >
                            <i className="fe fe-circle me-2" />
                            Connexion
                          </a>
                        </li>
                        {post.type_compte === "admin" && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/admin/user/profil"
                            >
                              <i className="fe fe-user me-2" />
                              Profile
                            </a>
                          </li>
                        )}
                        {post.type_compte === "instructeur" && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/dashboard/user/profil"
                            >
                              <i className="fe fe-user me-2" />
                              Profile
                            </a>
                          </li>
                        )}
                        {post.type_compte === "apprenant" && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/dashboard/user/profil"
                            >
                              <i className="fe fe-user me-2" />
                              Profile
                            </a>
                          </li>
                        )}

                        <li>
                          <a
                            className="dropdown-item"
                            href="./pages/student-subscriptions.html"
                          >
                            <i className="fe fe-star me-2" />
                            Subscription
                          </a>
                        </li>
                        <li>
                          <Link className="dropdown-item" to={"#"}>
                            <i className="fe fe-settings me-2" />
                            Settings
                          </Link>
                        </li>
                      </ul>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <li>
                          {connect ? (
                            <>
                              <button
                                className="dropdown-item"
                                onClick={logout}
                              >
                                <i className="fe fe-power me-2" />
                                Deconnexion
                              </button>
                            </>
                          ) : (
                            <>
                              <button className="dropdown-item" onClick={login}>
                                <i className="fe fe-power me-2" />
                                Connexion
                              </button>
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                {/* Button */}
                <button
                  className="navbar-toggler collapsed ms-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-default"
                  aria-controls="navbar-default"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar mt-0" />
                  <span className="icon-bar middle-bar" />
                  <span className="icon-bar bottom-bar" />
                </button>
              </div>
              {/* Collapse */}
              <div className="collapse navbar-collapse" id="navbar-default">
                <ul className="navbar-nav mt-3 mt-lg-0">
                 
                  <li className="nav-item">
                    {/* <Link class="nav-link" to={'/formation/ancien-sujet'} >Les anciens sujets</Link> */}
                    <Link
                      class="nav-link"
                      to={"/formation/bibliotheque/categorie"}
                      
                    >
                      Bibliotheque
                    </Link>
                    
                  </li>
                  <li className="nav-item dropdown">
                    {/* <Link class="nav-link" to={'/formation/ancien-sujet'} >Les anciens sujets</Link> */}
                    <Link
                      class="nav-link dropdown-toggle"
                      to={"/formation/ancien-sujet"}
                      id="navbarPages"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Les anciens sujets
                    </Link>
                    <ul
                      class="dropdown-menu dropdown-menu-arrow"
                      aria-labelledby="navbarPages"
                    >
                      <li class="dropdown-submenu dropend">
                        {documents?.length > 0
                          ? documents?.map((post, index) => (
                              <div key={index}>
                                <Link
                                  to={`/formation/ancien-sujet/type/${post.id}`}
                                  class="dropdown-item dropdown-list-group-item "
                                >
                                  {post.nom}
                                </Link>
                              </div>
                            ))
                          : "Pas de type"}
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="/formation/categorie"
                      id="navbarPages"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      categorie
                    </a>
                    <ul
                      class="dropdown-menu dropdown-menu-arrow"
                      aria-labelledby="navbarPages"
                    >
                      <li class="dropdown-submenu dropend">
                        {s_categories?.length > 0
                          ? s_categories?.map((post, index) => (
                              <div key={index}>
                                <Link
                                  to={`/formation/categorie/${post.id}`}
                                  class="dropdown-item dropdown-list-group-item "
                                >
                                  {post.nom}
                                </Link>
                              </div>
                            ))
                          : "Pas de categorie"}
                      </li>
                      <li>
                        <a class="nav-link" href="/formation/categorie">
                          
                          ...Autre
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <Link to={'#'}
                      className="nav-link dropdown-toggle"
                      id="navbarAccount"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Tableau de bord
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-arrow"
                      aria-labelledby="navbarAccount"
                    >
                      <li>
                        <h4 className="dropdown-header">Compte</h4>
                      </li>

                      <li className="dropdown-submenu dropend">
                        {post.type_compte === "admin" && (
                          <a
                            className="dropdown-item dropdown-list-group-item"
                            href="/admin/formation/index"
                          >
                            admin
                          </a>
                        )}
                        {post.type_compte === "instructeur" && (
                          <a
                            className="dropdown-item dropdown-list-group-item"
                            href="/dashboard/formation"
                          >
                            instructeur
                          </a>
                        )}
                        {post.type_compte === "apprenant" && (
                          <a
                            className="dropdown-item dropdown-list-group-item"
                            href="/dashboard/apprenant/cour"
                          >
                            apprenant
                          </a>
                        )}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          ))
        ) : (
          <div className="container-fluid px-0">
            <a className="navbar-brand" href="/">
              <img src="../../assets/images/brand/logo/favicon.ico" alt="LonyAcademy" />
            </a>
            {/* Mobile view nav wrap */}
            <div className="ms-auto d-flex align-items-center order-lg-3">
              
              <ul className="navbar-nav navbar-right-wrap ms-2 flex-row d-md-block">
                <li className="dropdown ms-2 d-inline-block position-static">
                  <Link
                    className="rounded-circle"
                    to="#"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-md avatar-indicators avatar-online">
                      {connect ? (
                        <>
                          <img
                            alt="avatar"
                            src={url}
                            className="rounded-circle"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            alt="avatar"
                            src="./assets/images/avatar/avatar-1.jpg"
                            className="rounded-circle"
                          />
                        </>
                      )}
                    </div>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end position-absolute mx-3 my-5">
                    <div className="dropdown-item">
                      <div className="d-flex">
                        {connect ? (
                          <>
                            <div className="avatar avatar-md avatar-indicators avatar-online">
                              <img
                                alt="avatar"
                                src={url}
                                className="rounded-circle"
                              />
                            </div>
                            <div className="ms-3 lh-1">
                              <h5 className="mb-1">
                                post.last_name; post.first_name
                              </h5>
                              <p className="mb-0">post.email</p>
                            </div>
                          </>
                        ) : (
                          "nom"
                        )}
                      </div>
                    </div>
                    <div className="dropdown-divider" />
                    <ul className="list-unstyled">
                      <li className="dropdown-submenu dropstart-lg">
                        <a
                          className="dropdown-item dropdown-list-group-item dropdown-toggle"
                          href="/admin"
                        >
                          <i className="fe fe-circle me-2" />
                          Connexion
                        </a>
                      </li>

                      <li>
                        <a
                          className="dropdown-item"
                          href="/dashboard/user/profil"
                        >
                          <i className="fe fe-user me-2" />
                          Profile
                        </a>
                      </li>

                      <li>
                        <a
                          className="dropdown-item"
                          href="./pages/student-subscriptions.html"
                        >
                          <i className="fe fe-star me-2" />
                          Subscription
                        </a>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fe fe-settings me-2" />
                          Settings
                        </Link>
                      </li>
                    </ul>
                    <div className="dropdown-divider" />
                    <ul className="list-unstyled">
                      <li>
                        {connect ? (
                          <>
                            <button className="dropdown-item" onClick={logout}>
                              <i className="fe fe-power me-2" />
                              Deconnexion
                            </button>
                          </>
                        ) : (
                          <>
                            <button className="dropdown-item" onClick={login}>
                              <i className="fe fe-power me-2" />
                              Connexion
                            </button>
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              {/* Button */}
              <button
                className="navbar-toggler collapsed ms-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-default"
                aria-controls="navbar-default"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar mt-0" />
                <span className="icon-bar middle-bar" />
                <span className="icon-bar bottom-bar" />
              </button>
            </div>
            {/* Collapse */}
            <div className="collapse navbar-collapse" id="navbar-default">
              <ul className="navbar-nav mt-3 mt-lg-0">
                
          <li className="nav-item">
                    {/* <Link class="nav-link" to={'/formation/ancien-sujet'} >Les anciens sujets</Link> */}
                    <Link
                      class="nav-link"
                      to={"/formation/bibliotheque/categorie"}
                      
                    >
                      Bibliotheque
                    </Link>
                    
                  </li>
                <li className="nav-item dropdown">
                  {/* <Link class="nav-link" to={'/formation/ancien-sujet'} >Les anciens sujets</Link> */}
                  <Link
                    class="nav-link dropdown-toggle"
                    to={"/formation/ancien-sujet"}
                    id="navbarPages"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Les anciens sujets
                  </Link>
                  <ul
                    class="dropdown-menu dropdown-menu-arrow"
                    aria-labelledby="navbarPages"
                  >
                    <li class="dropdown-submenu dropend">
                      {documents?.length > 0
                        ? documents?.map((post, index) => (
                            <div key={index}>
                              <Link
                                to={`/formation/ancien-sujet/type/${post.id}`}
                                class="dropdown-item dropdown-list-group-item "
                              >
                                {post.nom}
                              </Link>
                            </div>
                          ))
                        : "Pas de type"}
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="/formation/categorie"
                    id="navbarPages"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    categorie
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-arrow"
                    aria-labelledby="navbarPages"
                  >
                    <li class="dropdown-submenu dropend">
                      {s_categories?.length > 0
                        ? s_categories?.map((post, index) => (
                            <div key={index}>
                              <Link
                                to={`/formation/categorie/${post.id}`}
                                class="dropdown-item dropdown-list-group-item "
                              >
                                {post.nom}
                              </Link>
                            </div>
                          ))
                        : "Pas de categorie"}
                    </li>
                    <li>
                      <a class="nav-link" href="/formation/categorie">
                        
                        ...Autre
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarAccount"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Tableau de bord
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}
