import React from 'react'
import { useParams } from 'react-router-dom';
import { useVideo } from '../../../components/UseContext/useForma';

export default function ModifVideo() {
    const { id } = useParams();
    const {unVideo, setVideo, updateVideo} = useVideo(id)

    const onChangeVideo = (e) => {
        setVideo({
            ...unVideo,
            [e.target.name]: e.target.value
        })
    }
    

    const onSubmitVideo = (e) => {
        e.preventDefault();       
    
        unVideo["id"]= id
        console.log("uu ...",unVideo)
        updateVideo(unVideo);
      };
  return (
    <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Nouveau Video</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
            <form className="needs-validation" onSubmit={onSubmitVideo}>
                <div className="mb-3 mb-2">
                    <label className="form-label" htmlFor="title">
                        Nom
                        <span className="text-danger">*</span>
                    </label>
                    <input type="text" name='nom' onChange={onChangeVideo} className="form-control" value={unVideo.nom} />
                    
                </div>
                <div className="mb-3 mb-2">
                    <label className="form-label" htmlFor="title">
                        Durée
                        <span className="text-danger">*</span>
                    </label>
                    <input type="number" name='duree' onChange={onChangeVideo} className="form-control" value={unVideo.duree} />
                    
                </div>
                <div className="mb-3 mb-2">
                    <label className="form-label" htmlFor="title">
                        ordre
                        <span className="text-danger">*</span>
                    </label>
                    <input type="number" name='ordre' onChange={onChangeVideo} className="form-control" value={unVideo.ordre} />
                    
                </div>
                
                <div>
                <button type="submit" className="btn btn-primary" >Modifier</button>
                
                </div>
            </form>
        </div>
    </div>
  )
}
