import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useAnc_Type, { useBibliotheque_Cat } from '../../../../components/UseContext/useAncien';

export default function CatBibModif() {
    let {id} = useParams()

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const [base64Image, setBase64Image] = useState('');
    const top = {
        slug: id
    }
    const [type, setType] = useState([])
    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        // Convertir l'image en base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
    
        reader.readAsDataURL(file);
      };

    const {setCategorie, updateCat, unCategorie} = useBibliotheque_Cat(id)    
    
    const onChangeQcm = (e) => {
        setCategorie({
            ...unCategorie,
            [e.target.name]: e.target.value
        })
    }
    
    const onSubmitQcm = (e) => {
        e.preventDefault(); 

        if(base64Image.includes("base64"))
        {
          unCategorie["image"] = base64Image
        }else{
          delete unCategorie["image"]
        }

        unCategorie["id"]=id
       
        updateCat(unCategorie);
      };
  return (
    <>
    
        <div className="modal-content mx-5 py-5">
        <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Modifier la categorie</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmitQcm}>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Nom
                    
                </label>
                <input type="text" name='nom' onChange={onChangeQcm} value={unCategorie.nom} className="form-control" />
                
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Image
                    
                </label>
                <input type="file" onChange={handleFileChange} className="form-control" />
                    
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Description
                    
                </label>
                <input type="text" name='description' value={unCategorie.description} onChange={onChangeQcm} className="form-control" />
                
            </div>
            {/* <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Durée
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='duree' onChange={onChangeQcm} className="form-control" placeholder="Write a Category" required />
                <small>Field must contain a unique value</small>
                <div className="invalid-feedback">Please enter category.</div>
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Description
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='description' onChange={onChangeQcm} className="form-control" placeholder="Write a Category" required />
                <small>Field must contain a unique value</small>
                <div className="invalid-feedback">Please enter category.</div>
            </div> */}
            
            <div>
            <button type="submit" className="btn btn-primary">Modifier</button>
            <button type="button" className="btn btn-secondary mx-1" onClick={handleGoBack}>Retour</button>
            
            </div>
        </form>
        </div>
    </div>
    
    </>
  )
}
