import React from 'react'
import { Link } from 'react-router-dom'
import { useAnc_Document } from '../../../../components/UseContext/useAncien'
import useUtilisateur from '../../../../components/UseContext/useUtilisateur'

export default function AchatCard({post}) {
    
    const {unDoc: document} = useAnc_Document(post.document)
    const {user} = useUtilisateur(post.client_id)
    
    
  return (
    <>
        <tr>
            <td>
                <div className="form-check">
                <input type="checkbox" className="form-check-input" id="withdrawTwo" />
                <label className="form-check-label" htmlFor="withdrawTwo" />
                </div>
            </td>
            
            <td>{document.nom}</td>
            <td>
                {user.first_name} {user.last_name}
            </td>
            {/* <td>
                {post.annee}
            </td> */}
            
        </tr>
    </>
  )
}
