import React from "react";
import { Link } from "react-router-dom";
import { BASE } from "../../../_services/caller.service";

export default function NavF({ post }) {
  
  let url = BASE(post.image);
  
  return (
    <>
    
      <div className="card mb-4 card-hover">
        <Link to={`/formation/bibliotheque/categorie/${post.id}`}>
          <img src={url} alt="course" className="card-img-top" />
        </Link>
        {/* <!-- Card body --> */}
        <div className="card-body">
          <h3 className="h4 mb-2 text-truncate-line-2">
            <Link to="#" className="text-inherit">
              {post.nom}
            </Link>
          </h3>
          
        </div>
        
      </div>
    </>
  );
}
