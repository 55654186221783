import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useAnc_Type, { useAnc_Matiere } from '../../../../components/UseContext/useAncien';

export default function MatiereModif() {
    let {id} = useParams()
    const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
    
    const [type, setType] = useState([])
    const top = {
        "id": id
    }

    const {unMatiere, setMatiere, updateMatiere} = useAnc_Matiere(id)  
    
    const onChangeQcm = (e) => {
        setMatiere({
            ...unMatiere,
            [e.target.name]: e.target.value
        })
    }
    
    const onSubmitQcm = (e) => {
        e.preventDefault(); 
        
        unMatiere["id"] = id
    
        updateMatiere(unMatiere);
      };
  return (
    <>
    <div className="modal-content mx-5 py-5">
        {/* <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Create New Type</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div> */}
        
            <div className="modal-body">
            <form className="needs-validation" onSubmit={onSubmitQcm}>
                <div className="mb-3 mb-2">
                    <label className="form-label" htmlFor="title">
                        Nom
                        <span className="text-danger">*</span>
                    </label>
                    <input type="text" name='nom' onChange={onChangeQcm} className="form-control" value={unMatiere.nom} required />
                    <small>Field must contain a unique value</small>
                    <div className="invalid-feedback">Please enter category.</div>
                </div>
                
                <div>
                <button type="submit" className="btn btn-primary">Modifier</button>
                <button type="button" className="btn btn-secondary mx-1" onClick={handleGoBack}>Retour</button>
                
                </div>
            </form>
            </div>
        
        </div>
    </>
  )
}
