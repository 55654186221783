import React from "react";
import useUtilisateur from "../../../components/UseContext/useUtilisateur";
import useForma from "../../../components/UseContext/useForma";

export default function AchatCard({ post }) {
  const { unFormation: document } = useForma(post.formation_id);
  const { user } = useUtilisateur(post.client_id);
  
  return (
    <>
      <tr>
        <td>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="withdrawTwo"
            />
            <label className="form-check-label" htmlFor="withdrawTwo" />
          </div>
        </td>

        <td>{document.nom}</td>
        <td>
          {user.first_name} {user.last_name}
        </td>
        {/* <td>
                {post.annee}
            </td> */}
      </tr>
    </>
  );
}
