import React from "react";

import { useBibliotheque_Cat } from "../../../components/UseContext/useAncien";
import NavF from "./NavF";

export default function CardNav() {
  const top = {
    all: "all",
  };

  const { Categorie } = useBibliotheque_Cat(top);
  
  return (
    <>
      <div>
        {/* Bg cover */}
        <section
          className="py-8"
          style={{
            background: "linear-gradient(270deg, #9d4eff 0%, #782af4 100%)",
          }}
        />
        {/* Page header */}
        <section className="bg-white shadow-sm">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-md-flex align-items-center justify-content-between bg-white pt-3 pb-3 pb-lg-5">
                  <div className="d-md-flex align-items-center text-lg-start text-center">
                    <div className="me-3 mt-n8">
                      <img
                        src="../../../assets/images/brand/logo/favicon.ico"
                        className="avatar-xxl rounded border p-4 bg-white"
                        alt="LonyAcademy "
                      />
                    </div>
                    <div className="mt-3 mt-md-0">
                      <h1 className="mb-0 fw-bold me-3">
                        Les categories de la bibliotheque
                      </h1>
                    </div>
                  
                  </div>
                  {/* Dropdown */}
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Content --> */}
      <section className="pb-5 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <!-- Side Navbar --> */}
              <ul className="nav nav-lb-tab mb-6" id="tab" role="tablist">
                <li className="nav-item ms-0" role="presentation">
                  <a
                    className="nav-link active"
                    id="bookmarked-tab"
                    data-bs-toggle="pill"
                    href="#bookmarked"
                    role="tab"
                    aria-controls="bookmarked"
                    aria-selected="true"
                  >
                    Categories du livres 
                  </a>
                </li>
              </ul>
              {/* <!-- Tab content --> */}
              <div className="tab-content" id="tabContent">
                <div
                  className="tab-pane fade show active"
                  id="bookmarked"
                  role="tabpanel"
                  aria-labelledby="bookmarked-tab"
                >
                  <div className="row">
                    {Categorie?.map((post) => (
                      <>
                        <div className="col-lg-3 col-md-6 col-12">
                          {/* <!-- Card --> */}
                          <NavF post={post} />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
