
import React from 'react'

import FormationCardQcm from './FormationCardQcm';
import { useQcm } from '../../../components/UseContext/useForma';



export default function FormationQcm({slug}) {
    const sluger = {
        "formation_slug": slug
    }
    
  const {qcm_s: qcms, isLoading} = useQcm(sluger)

  if (isLoading) {
    return <div>Chargement...</div>;
  }
  
  return (
    <>
    <div className="card mb-4">
    {/* Card header */}
    <div className="card-header border-bottom-0">
        <h3 className="h4 mb-3">Listes des QCM</h3>
        <div className="row align-items-center">
       
        
        </div>
    </div>
    {/* Table */}
    <div className="table-responsive">
        <table className="table mb-0 text-nowrap table-hover table-centered table-with-checkbox">
        <thead className="table-light">
            <tr>
            <th>
                <div className="form-check">
                <input type="checkbox" className="form-check-input" id="checkAll" />
                <label className="form-check-label" htmlFor="checkAll" />
                </div>
            </th>
            
            <th>Nom</th>
            <th>Description</th>
            <th>Date</th>
            <th />
            </tr>
        </thead>
        <tbody>
        {qcms?.length > 0 ? 
          qcms.map((post, index) => (
            <FormationCardQcm key={index} qcm={post} slug={slug} />
          ))
          : 'Pas de QCM'
          }            
            
        </tbody>
        </table>
        
    </div>
    </div>

    </>
  )
}
