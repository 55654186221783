import React from "react";
import { BASE } from "../../../../_services/caller.service";
import { Link } from "react-router-dom";

export default function CardListDoc({ post }) {
  let url = BASE(post.miniature);
  

  return (
    <>
      <div className="col-lg-3 col-md-6 col-12">
        {/* <!-- Card --> */}
        <div className="card mb-4 card-hover">
          <Link to={`/formation/ancien-sujet/${post.id}`}>
            <img src={url} alt="course" className="card-img-top" />
          </Link>
          {/* <!-- Card body --> */}
          <div className="card-body">
            <h3 className="h4 mb-2 text-truncate-line-2">
              <Link to="#" className="text-inherit">
                {post.nom}
              </Link>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
