import React from 'react'
import { useQcm } from '../../../components/UseContext/useForma';
import { useParams } from 'react-router-dom';

export default function QcModif() {
    let {id, slug} = useParams()

    const {unQcm, setQcm, updateQcm} = useQcm(id)

    const onChangeQcm = (e) => {
        setQcm({
            ...unQcm,
            [e.target.name]: e.target.value
        })
    }
    

    const onSubmitQcm = (e) => {
        e.preventDefault();       
    
        unQcm["id"]= id
        
        updateQcm(unQcm);
      };
  return (
    <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Nouveau QCM</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmitQcm}>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Nom
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='nom' onChange={onChangeQcm} className="form-control" value={unQcm.nom} />
                
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Durée
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='duree' onChange={onChangeQcm} className="form-control" value={unQcm.duree} />
                
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Description
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='description' onChange={onChangeQcm} className="form-control" value={unQcm.description} />
                
            </div>
            
            <div>
            <button type="submit" className="btn btn-primary" >Modifier</button>
            
            </div>
        </form>
        </div>
        </div>
  )
}
