import React from 'react'
import Livres from './Livres'
import { BASE } from '../../../_services/caller.service';
import { Link } from 'react-router-dom';

export default function LivreNav({livre}) {
  let url = BASE(livre.image);

  console.log("ll ..",livre)
  return (
    <div className="col-lg-3 col-md-6 col-12">
    <div className="card mb-4 card-hover">
            <Link to={`/formation/bibliotheque/categorie/detail/${livre.slug}`}>
            <img src={url} alt="course" className="card-img-top" />
            </Link>
            {/* <!-- Card body --> */}
            <div className="card-body">
            <h3 className="h4 mb-2 text-truncate-line-2">
                <Link to="#" className="text-inherit">
                {livre.nom}
                </Link>
            </h3>
            {/* <!-- List inline --> */}
            
            <div className="mt-3 d-flex align-baseline lh-1">
                <span className="text-warning mx-1">Publier le</span>
                <span className="fs-6">{livre.date}</span>
            </div>
            </div>
            {/* <!-- Card footer --> */}
            <div className="card-footer">
            <div className="row align-items-center g-0">
                <div className="col-auto">
                 Auteur =
                </div>
                <div className="col ms-2">
                <span>
                    {livre.auteur}
                </span>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}
