import React, { useState } from 'react'
import { useBibliotheque_Aut, useBibliotheque_Cat, useBibliotheque_Livre } from '../../../../components/UseContext/useAncien';
import CatBibCard from './LivreCard';
import LivreCard from './LivreCard';
import useUtilisateur from '../../../../components/UseContext/useUtilisateur';

export default function Livre() {
    const [base64Image, setBase64Image] = useState('');
    const [base64Doc, setBase64Doc] = useState('');
    const [type, setType] = useState([])
    const onChangeQcm = (e) => {
        setType({
            ...type,
            [e.target.name]: e.target.value
        })
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        // Convertir l'image en base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
    
        reader.readAsDataURL(file);
      };
      const DocumentChange = (e) => {
        const file = e.target.files[0];
    
        // Convertir l'image en base64
        const reader = new FileReader();
        reader.onloadend = () => {
            setBase64Doc(reader.result);
        };
    
        reader.readAsDataURL(file);
        };
    const top = {
        all: "all"
    }
    const {Auteur} = useBibliotheque_Aut(top)
    const {Categorie} = useBibliotheque_Cat(top)
    const {connect} = useUtilisateur()
    
    const {Livre, addLivre, isLoading} = useBibliotheque_Livre(top)
    
    if (isLoading) {
    return <div>Chargement...</div>;
    }
      

    const onSubmitQcm = (e) => {
    e.preventDefault();       

    if(base64Image.includes("base64"))
    {
      type["image"] = base64Image
    }else{
      delete type["image"]
    }

    if(base64Doc.includes("base64"))
    {
        type['document'] = base64Doc
    }else{
    delete type['document']
    }
    
    type["admin_id"] = connect

    addLivre(type);
    };
  return (
    <>
    <div className="card mb-4 mx-3 my-3">
        {/* Card header */}
        <div className="card-header border-bottom-0">
            <h3 className="h4 mb-3">Listes des Livres</h3>
            <div className="row align-items-center">
            
            <div className="col-lg-2 col-md-6 text-lg-end">
                {/* Button */}
                <button className="btn btn-outline-secondary btn-icon" data-bs-toggle="modal" data-bs-target="#newType">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>

                </button>
            </div>
            </div>
        </div>
        {/* Table */}
        <div className="table-responsive">
            <table className="table mb-0 text-nowrap table-hover table-centered table-with-checkbox">
            <thead className="table-light">
                <tr>
                <th>
                    <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="checkAll" />
                    <label className="form-check-label" htmlFor="checkAll" />
                    </div>
                </th>
                
                <th>Reference</th>
                <th>Nom</th>
                <th>Description</th>
                <th>Categorie</th>
                <th>Auteur</th>
                <th>Image</th>
                
                <th />
                </tr>
            </thead>
            <tbody>
            {Livre?.length > 0 ? 
            Livre.map((post) => (
                <LivreCard type={post} />
            ))
            : 'Pas de livre'
            }            
                
            </tbody>
            
            </table>
            
        </div>
    </div>

    {/* Modal QCM*/}
    <div className="modal fade" id="newType" tabIndex={-1} role="dialog" aria-labelledby="newTypeLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">Ajout des livres</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
        <form className="needs-validation" onSubmit={onSubmitQcm}>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Nom
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='nom' onChange={onChangeQcm} className="form-control" placeholder="Write a Category" required />
                
            </div>
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Reference
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='reference' onChange={onChangeQcm} className="form-control" placeholder="Write a Category" required />
                
            </div>
            
            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Image
                    
                </label>
                <input type="file" onChange={handleFileChange} className="form-control" required />
                
            </div>

            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Document
                    
                </label>
                <input type="file" onChange={DocumentChange} className="form-control" required />
                
            </div>

            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Description
                    <span className="text-danger">*</span>
                </label>
                <input type="text" name='description' onChange={onChangeQcm} className="form-control" placeholder="Write a Category" required />
                
            </div>

            <div className="mb-3 mb-2">
                <label className="form-label" htmlFor="title">
                    Choisissez la categorie
                    
                </label>
                <select class="form-select " name='categorie_id' onChange={onChangeQcm} aria-label="Default select example">
                    <option selected>Choisissez une categorie</option>
                    {Categorie?.length > 0 ? 
                    Categorie.map((post) => (
                        <option value={post.id}>{post.nom}</option>
                    ))
                    : 'Pas de categorie'
                    } 
                
                </select>
            </div>

            <div className="mb-3 mb-2">
            <label className="form-label" htmlFor="title">
                Choisissez l'auteur
                
            </label>
            <select class="form-select " name='auteur_id' onChange={onChangeQcm} aria-label="Default select example">
                <option selected>Choisissez un auteur</option>
                {Auteur?.length > 0 ? 
                Auteur.map((post) => (
                    <option value={post.id}>{post.nom}</option>
                ))
                : "Pas d'auteur"
                } 
            
            </select>
            </div>
            
            <div>
            <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Add New Type</button>
            
            </div>
        </form>
        </div>
        </div>
    </div>
    </div> 
    </>
  )
}
