import React from 'react'
import { useAnc_Document } from '../../../../components/UseContext/useAncien'
import CardListDoc from './CardListDoc'
import Slider from '../../../../components/Public/Slider'
import { useParams } from 'react-router-dom'

export default function ListDocument() {
  let {id} = useParams()
  
  const top = {
    type_id: id,
  }
  
  const {document: documents} = useAnc_Document(top)
    
  return (
    <>
    

    <main>
        <div>
            {/* Bg cover */}
            <section className="py-8" style={{background: 'linear-gradient(270deg, #9d4eff 0%, #782af4 100%)'}} />
            {/* Page header */}
            <section className="bg-white shadow-sm">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="d-md-flex align-items-center justify-content-between bg-white pt-3 pb-3 pb-lg-5">
                        <div className="d-md-flex align-items-center text-lg-start text-center">
                        <div className="me-3 mt-n8">
                            <img src={'url'} className="avatar-xxl rounded border p-4 bg-white" alt="bootstarp " />
                        </div>
                        <div className="mt-3 mt-md-0">
                            <h1 className="mb-0 fw-bold me-3">{documents.length}</h1>
                        </div>
                        <div>
                            {/* <span className="ms-2 fs-6">
                            <span className="text-dark fw-medium">21.9K</span>
                            students
                            </span> */}
                            <span className="ms-2 fs-6">
                            <span className="text-dark fw-medium">MMM </span>
                            formation
                            </span>
                            {/* <span className="ms-2 fs-6">
                            <span className="text-dark fw-medium">11</span>
                            Hours
                            </span> */}
                        </div>
                        </div>
                        {/* Dropdown */}
                        
                    </div>
                    
                    </div>
                </div>
                </div>
            </section>
        </div>

        {/* <!-- Content --> */}
        <section className="pb-5 py-md-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {/* <!-- Side Navbar --> */}
                        <ul className="nav nav-lb-tab mb-6" id="tab" role="tablist">
                            <li className="nav-item ms-0" role="presentation">
                                <a className="nav-link active" id="bookmarked-tab" data-bs-toggle="pill" href="#bookmarked" role="tab" aria-controls="bookmarked" aria-selected="true">Anciens sujets</a>
                            </li>
                            
                        </ul>
                        {/* <!-- Tab content --> */}
                        <div className="tab-content" id="tabContent">
                            <div className="tab-pane fade show active" id="bookmarked" role="tabpanel" aria-labelledby="bookmarked-tab">
                                <div className="row">
                                {documents?.map((post) => {
                                return <CardListDoc post={post} />
                              })}                                   
                                    
                                </div>
                            
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    </>
  )
}
