import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { sousCatService } from "../../../_services";
import { useQuery } from "@tanstack/react-query";
import { BASE } from "../../../_services/caller.service";
import { useSousCat } from "../../../components/UseContext/useForma";
import SousModifCard from "./SousModifCard";

export default function SousModif() {
  let { id } = useParams();
  
  const { updateSousCat } = useSousCat();
  const [base64Image, setBase64Image] = useState("");
  const [nom, setNom] = useState([]);
  const top = {
    id: id,
  };
  // const { sous_categories } = useSousCat(top);

  const {
    // data: categorie,
    error,
    isLoading,
} = useQuery({
  queryKey: ["categorie", id],
  queryFn: () =>
    sousCatService.unSousCat(id)
  .then(res => {
    if(res.data.etat===true){
        setNom(res.data.donnee);
    } else {
        toast.error("Les identifiants sont incorrects");
    }
}),
  onerror: (error) => console.log(error),
});
if (isLoading) {
  return <div>Chargement...</div>;
}
  
  // const handleSousCatChange = (index, value) => {
  //   const newSousCategories = [...nom];
  //   newSousCategories[index].nom = value;
  //   setNom(newSousCategories);
  // };

  
  const onChange = (e) => {
    setNom({
      ...nom,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Convertir l'image en base64
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("form ..", nom)
    if (base64Image.includes("base64")) {
      nom["image"] = base64Image;
    } else {
      delete nom["image"];
    }
    nom["id"] = id;
    updateSousCat(nom);
    
  };
  
  return (
    <>
      <div className="modal-dialog modal-dialog-centered modal-lg my-5">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title mb-0" id="newCatgoryLabel">
              Create New Sous-Categorie
            </h4>
          </div>
          
            <SousModifCard nom={nom} onSubmit={onSubmit} onChange={onChange} handleFileChange={handleFileChange} />
            
        </div>
      </div>
    </>
  );
}
