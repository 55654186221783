import React from "react";
import { Link } from "react-router-dom";
import { useBibliotheque_Cat } from "../../../../components/UseContext/useAncien";
import { BASE } from "../../../../_services/caller.service";

export default function CatBibCard({ type }) {
  const { deleteCat } = useBibliotheque_Cat();
  let url = BASE(type.image)
  return (
    <>
      <tr>
        <td>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="withdrawTwo"
            />
            <label className="form-check-label" htmlFor="withdrawTwo" />
          </div>
        </td>

        <td>{type.nom}</td>
        <td>{type.description}</td>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <img src={url} alt="course" className="rounded img-4by3-lg" />
            </div>
          </div>
        </td>

        <td>
          <span className="dropdown dropstart">
            <a
              className="btn-icon btn btn-ghost btn-sm rounded-circle"
              href="#"
              role="button"
              id="paymentDropdown"
              data-bs-toggle="dropdown"
              data-bs-offset="-20,20"
              aria-expanded="false"
            >
              <i className="fe fe-more-vertical" />
            </a>
            <span className="dropdown-menu" aria-labelledby="paymentDropdown">
              
              <Link
                to={`/admin/bibliotheque_numerique/categorie/${type.id}`}
                className="dropdown-item"
              >
                <i className="fe fe-edit dropdown-item-icon" />
                Edit
              </Link>
              <button className="dropdown-item" onClick={() => deleteCat(type)}>
                <i className="fe fe-trash dropdown-item-icon" />
                Remove
              </button>
            </span>
          </span>
        </td>
      </tr>
    </>
  );
}
