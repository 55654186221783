import React from 'react'
import { BASE } from '../../_services/caller.service'

export default function UserImg({post}) {
    let url = BASE(post.avatar)
  return (
    <div className="d-flex align-items-center mb-4 mb-lg-0">
        <img src={url} id="img-uploaded" className="avatar-xl rounded-circle" alt="avatar" />
        <div className="ms-3">
            <h4 className="mb-0">Votre Profil</h4>
            <p className="mb-0">{post.last_name} {post.first_name}</p>
        </div>
    </div>
  )
}
