import React from "react";
import { Route, Routes } from "react-router-dom";
import Dash from "../Public/Dash";
import Dashboard from "../Public/Dashboard";
import Error from "../../_utils/Error";
import FormationList from "./Formation/FormationList";

import FormQcmChapit from "./Formation/FormQcmChapit";
import Discut from "./Formation_Cours/Discut";
import Question from "./Question/Question";
import FormationEdit from "./Formation/FormationEdit";
import UserProfil from "./UserProfil";
import Userlist from "./UserList";
import Reponse from "./Question/Reponse";
import Videos from "./Video/Video";
import SeanceTravail from "./SeanceTravail/SeanceTravail";
import ChapitreModif from "./Chapitre/ChapitreModif";
import AutreQCM from "./Qcm/Autre/AutreQCM";
import Cour from "../Apprenant/Cour";
import MesCategorie from "../Apprenant/MesCategorie/MesCategorie";
import Examen from "./Examen/Examen";
import ListExamen from "./Examen/ListExamen";
import MesExamen from "../Apprenant/MesExamen/MesExamen";
import MesDocument from "../Apprenant/MesDocument/MesDocument";
import QcModif from "./Qcm/QcModif";
import ModifVideo from "./Video/ModifVideo";

export default function InstructerRouter() {
  return (
    <>
      <Routes>
        <Route element={<Dash />}>
          <Route index element={<Dashboard />} />
          {/* <Route path='dashboard' element={<Dashboard />}/> */}

          <Route path="formation">
            <Route index element={<FormationList />} />

            <Route path="examen">
              <Route index element={<Examen />} />
              <Route path="code/:a_id/:e_id/:id" element={<ListExamen />} />
            </Route>

            <Route path="chapitre">
              <Route path="qcm/:slug" element={<FormQcmChapit />} />
              <Route path="qcModif/:id/:slug" element={<QcModif />} />
              <Route path="modif/:id/:slug" element={<ChapitreModif />} />
            </Route>

            <Route path="video">
              <Route path=":id" element={<Videos />} />
              <Route path="modif/:id" element={<ModifVideo />} />
            </Route>

            <Route path="question">
              <Route path=":id" element={<Question />} />
              <Route path="reponse/:id" element={<Reponse />} />
            </Route>

            <Route path="modif/:slug" element={<FormationEdit />} />
            <Route path="qcm/detail/:id" element={<AutreQCM />} />
            {/* // QcmDetail */}
            <Route path="discution/:id/:slug" element={<Discut />} />
            <Route path="seanceTravail/:id/:slug" element={<SeanceTravail />} />
          </Route>

          <Route path="apprenant">
            <Route path="cour" element={<Cour />} />
            <Route path="document" element={<MesDocument />} />
            <Route path="examen" element={<MesExamen />} />
            <Route path="categorie" element={<MesCategorie />} />
            <Route path="index" element={<Userlist />} />
          </Route>

          <Route path="user">
            <Route path="seanceTravail" element={<UserProfil />} />
            <Route path="profil" element={<UserProfil />} />
            <Route path="index" element={<Userlist />} />
          </Route>

          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </>
  );
}
