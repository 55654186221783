import React from "react";

import useUtilisateur from "../../UseContext/useUtilisateur";
import { Link } from "react-router-dom";

export default function SideMenu({ user }) {
  const top = {
    id: user,
  };

  const { user: posts } = useUtilisateur(top);

  return (
    <>
      {/* Side navbar */}
      <nav className="navbar navbar-expand-md shadow-sm mb-4 mb-lg-0 sidenav">
        {/* Menu */}
        <Link
          className="d-xl-none d-lg-none d-md-none text-inherit fw-bold"
          to="#"
        >
          Menu
        </Link>
        {/* Button */}
        <button
          className="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary text-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidenav"
          aria-controls="sidenav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fe fe-menu" />
        </button>
        {/* Collapse navbar */}
        <div className="collapse navbar-collapse" id="sidenav">
            {posts?.map((post, index) => (

            <div key={index} className="navbar-nav flex-column">
                <span className="navbar-header">Dashboard</span>
                <ul className="list-unstyled ms-n2 mb-4">
                {/* Nav item */}
                <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                    <i className="fe fe-credit-card nav-icon" />
                    Accueil
                    </Link>
                </li>

                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="invoice.html">
                        <i className="fe fe-clipboard nav-icon" />
                        Invoice
                        </Link>
                    </li> */}
                </ul>
                <span className="navbar-header">Formation</span>
                <ul className="list-unstyled ms-n2 mb-4">
                {/* Nav item */}
                {post.type_compte === "instructeur" && (
                    <>
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard/formation">
                        <i className="fe fe-credit-card nav-icon" />
                        Formation
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link
                        className="nav-link"
                        to="/dashboard/user/seanceTravail"
                        >
                        <i className="fe fe-credit-card nav-icon" />
                        Seance de Travail
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard/formation/examen">
                        <i className="fe fe-credit-card nav-icon" />
                        Examen
                        </Link>
                    </li>
                    </>
                )}
                {post.type_compte === "apprenant" && (
                    <>
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard/apprenant/cour">
                        <i className="fe fe-credit-card nav-icon" />
                        les formations que vous suivez
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                        className="nav-link"
                        to="/dashboard/apprenant/document"
                        >
                        <i className="fe fe-credit-card nav-icon" />
                        les Documents que vous avez acheter
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                        className="nav-link"
                        to="/dashboard/apprenant/categorie"
                        >
                        <i className="fe fe-credit-card nav-icon" />
                        Mes categorie
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard/apprenant/examen">
                        <i className="fe fe-credit-card nav-icon" />
                        Mes Examen
                        </Link>
                    </li>
                    </>
                )}

                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="invoice.html">
                        <i className="fe fe-clipboard nav-icon" />
                        Invoice
                        </Link>
                    </li> */}
                </ul>
                {/* Navbar header */}
                <span className="navbar-header">Account Settings</span>
                <ul className="list-unstyled ms-n2 mb-0">
                {/* Nav item */}
                <li className="nav-item">
                    <Link className="nav-link" to="/dashboard/user/profil">
                    <i className="fe fe-settings nav-icon" />
                    Edit Profile
                    </Link>
                </li>
                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="security.html">
                        <i className="fe fe-user nav-icon" />
                        Security
                        </Link>
                    </li> */}
                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="social-profile.html">
                        <i className="fe fe-refresh-cw nav-icon" />
                        Social Profiles
                        </Link>
                    </li> */}
                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="notifications.html">
                        <i className="fe fe-bell nav-icon" />
                        Notifications
                        </Link>
                    </li> */}
                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="profile-privacy.html">
                        <i className="fe fe-lock nav-icon" />
                        Profile Privacy
                        </Link>
                    </li> */}
                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="delete-profile.html">
                        <i className="fe fe-trash nav-icon" />
                        Delete Profile
                        </Link>
                    </li> */}
                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="linked-accounts.html">
                        <i className="fe fe-user nav-icon" />
                        Linked Accounts
                        </Link>
                    </li> */}
                {/* Nav item */}
                {/* <li className="nav-item">
                        <Link className="nav-link" to="../index.html">
                        <i className="fe fe-power nav-icon" />
                        Sign Out
                        </Link>
                    </li> */}
                </ul>
            </div>
            ))}
        </div>
      </nav>
    </>
  );
}
