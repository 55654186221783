import React from "react";
import CardNav from "./CardNav";

export default function NavBiblio() {
  return (
    <>
      <main>
        <CardNav />
      </main>
    </>
  );
}
