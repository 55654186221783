import React from 'react'
import { Link } from 'react-router-dom'

export default function AdminSideMenu() {
  return (
    <>
      {/* Sidebar */}
      <nav className="navbar-vertical navbar">
        <div className="vh-100" data-simplebar>
          {/* Brand logo */}
          <Link className="navbar-brand" to="/">
            <img src="../../assets/images/brand/logo/favicon.ico" alt="LonyAcademy" />
          </Link>
          {/* Navbar nav */}
          <ul className="navbar-nav flex-column" id="sideNavbar">
            <li className="nav-item">
              <Link className="nav-link" to="/admin/dashboard" >
                <i className="nav-icon fe fe-home me-2" />
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#navCourses" aria-expanded="false" aria-controls="navCourses">
                <i className="nav-icon fe fe-book me-2" />
                Categorie
              </Link>
              <div id="navCourses" className="collapse" data-bs-parent="#sideNavbar">
                <ul className="nav flex-column">
                  
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/formation/categorie">Liste Categorie</Link>
                  </li>
                  
                </ul>
              </div>
            </li>
            {/* Nav item */}
            <li className="nav-item">
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#navProfile" aria-expanded="false" aria-controls="navProfile">
                <i className="nav-icon fe fe-user me-2" />
                User
              </Link>
              <div id="navProfile" className="collapse" data-bs-parent="#sideNavbar">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/user/index">Liste</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/admin/user/add">Ajouter</Link>
                  </li> */}
                </ul>
              </div>
            </li>
            {/* Nav item */}
            <li className="nav-item">
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#navCMS" aria-expanded="false" aria-controls="navCMS">
                <i className="nav-icon fe fe-book-open me-2" />
                Formation
              </Link>
              <div id="navCMS" className="collapse" data-bs-parent="#sideNavbar">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link active" to="/admin/formation/index">Liste des formations</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/admin/formation/slider">Slider</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/formation/examen">Liste des examens</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/admin/formation/achat">Formation Acheter</Link>
                  </li> */}
                  
                </ul>
              </div>
            </li>
            {/* Nav item */}
            <li className="nav-item">
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#navProject" aria-expanded="false" aria-controls="navProject">
                <i className="nav-icon fe fe-file me-2" />
                Les anciens sujets
              </Link>
              <div id="navProject" className="collapse" data-bs-parent="#sideNavbar">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ancien_sujet/type">Type</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ancien_sujet/niveau">Niveau</Link>
                  </li>
                  
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ancien_sujet/matiere">Matiere</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ancien_sujet/pays">Pay</Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ancien_sujet/document">Document</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/ancien_sujet/AchatDocument">Achat document</Link>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#navBib" aria-expanded="false" aria-controls="navBib">
                <i className="nav-icon fe fe-file me-2" />
                bibliotheque numerique
              </Link>
              <div id="navBib" className="collapse" data-bs-parent="#sideNavbar">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/bibliotheque_numerique/categorie">Categorie</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/bibliotheque_numerique/auteur">Auteur</Link>
                  </li>
                  
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/bibliotheque_numerique/livre">Livre</Link>
                  </li>
                  
                </ul>
              </div>
            </li>
            
          </ul>
        </div>
      </nav>

    </>
  )
}
