import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { BASE } from "../../../_services/caller.service";
import { useSousCat } from "../../../components/UseContext/useForma";

export default function SousCard({ sous_cat }) {
  const { deleteSousCat } = useSousCat(sous_cat);

  let url = BASE(sous_cat.image);
  return (
    <>
      <tr>
        <td>
          <div className="ms-3">
            <h4 className="mb-1 h5">
              <a href="#" className="text-inherit">
                {sous_cat.nom}
              </a>
            </h4>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <img src={url} alt="course" className="rounded img-4by3-lg" />
            </div>
          </div>
        </td>

        <td>
          <span className="dropdown dropstart">
            <a
              className="btn-icon btn btn-ghost btn-sm rounded-circle"
              href="#j"
              role="button"
              id="courseDropdown1"
              data-bs-toggle="dropdown"
              data-bs-offset="-20,20"
              aria-expanded="false"
            >
              <i className="fe fe-more-vertical" />
            </a>
            <span className="dropdown-menu" aria-labelledby="courseDropdown1">
              <span className="dropdown-header">Setting</span>
              <Link
                to={`/admin/formation/sous/modif/${sous_cat.id}`}
                className="dropdown-item"
              >
                <i className="fe fe-edit dropdown-item-icon" />
                Edit
              </Link>
              <button
                className="dropdown-item"
                onClick={() => deleteSousCat(sous_cat)}
              >
                <i className="fe fe-trash dropdown-item-icon" />
                Remove
              </button>
            </span>
          </span>
        </td>
      </tr>
    </>
  );
}
