import React from 'react'
import { Link } from 'react-router-dom'
import { useBibliotheque_Livre } from '../../../../components/UseContext/useAncien';
import { BASE } from '../../../../_services/caller.service';

export default function LivreCard({type}) {
  const {deleteLivre} = useBibliotheque_Livre()
  let url = BASE(type.image);
  return (
    <>
        <tr>
            <td>
                <div className="form-check">
                <input type="checkbox" className="form-check-input" id="withdrawTwo" />
                <label className="form-check-label" htmlFor="withdrawTwo" />
                </div>
            </td>
            
            <td>{type.reference}</td>
            <td>{type.nom}</td>
            <td>{type.description}</td>
            <td>{type.categorie}</td>
            <td>{type.auteur}</td>
            <td>
              <div className="d-flex align-items-center">
                <div>
                  
                    <img
                      src={url}
                      alt="course"
                      className="rounded img-4by3-lg"
                    />
                  
                </div>
                
              </div>
            </td>
           
            
            <td>
                <span className="dropdown dropstart">
                <a className="btn-icon btn btn-ghost btn-sm rounded-circle" href="#" role="button" id="paymentDropdown" data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                    <i className="fe fe-more-vertical" />
                </a>
                <span className="dropdown-menu" aria-labelledby="paymentDropdown">
                    <span className="dropdown-header">Setting</span>
                    <Link to={`/admin/bibliotheque_numerique/livre/${type.id}`} className="dropdown-item" >
                    <i className="fe fe-edit dropdown-item-icon" />
                    Edit
                    </Link>
                    <button className="dropdown-item"onClick={()=>deleteLivre(type)}>
                    <i className="fe fe-trash dropdown-item-icon" />
                    Remove
                    </button>
                </span>
                </span>
            </td>
        </tr>
    </>
  )
}
