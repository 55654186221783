import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { accountService } from '../../../_services';
import toast from 'react-hot-toast';

import { Base } from '../../../_services/caller.service';
import FormationContext from '../../../components/UseContext/formation.context';
import useForma, { useChapitre } from '../../../components/UseContext/useForma';

export default function VideoVue() {
  let {slug} = useParams()
  // const { user } = useContext(FormationContext)
  const sluger = {
    "formation_slug": slug,
    "all": "all",
  }
  const {chapitre: chapitres} = useChapitre(sluger)
  const {unFormation: cour} = useForma(slug)
  
   
    const [url, seturl] = useState('');

    // pour annuler le click droit
    useEffect(() => {
      const handleContextMenu = (e) => {
        e.preventDefault();
      };
  
      document.addEventListener('contextmenu', handleContextMenu, false);
  
      return () => {
        // Nettoyer l'écouteur d'événements lors du démontage du composant
        document.removeEventListener('contextmenu', handleContextMenu, false);
      };
    }, []);
    // fin
    
  return (
    <>
    <main>
      <section className="mt-6 course-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* Tab content */}
              <div className="tab-content content" id="course-tabContent">
                <div className="tab-pane fade show active" id="course-intro" role="tabpanel" aria-labelledby="course-intro-tab">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                      <h3 className="mb-0 text-truncate-line-2">Introduction</h3>
                    </div>
                    
                  </div>
                  {/* Video */}
                  <div id="video_container" className="embed-responsive position-relative w-100 d-block overflow-hidden p-0" style={{height: 600}}>
                    {/* <iframe className="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100" width={560} height={315} src={url} title="Geeks - Academy and LMS Template" frameBorder={0} /> */}

                    
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Card */}
      <section className="card course-sidebar" id="courseAccordion">
        {/* List group */}
        <ul className="list-group list-group-flush" style={{height: 850}} data-simplebar>
          <li className="list-group-item">
            <h4 className="mb-0">{cour.nom}</h4>
          </li>
          {/* List group item */}
          <li className="list-group-item">
          {chapitres?.length > 0 ? 
              chapitres.map((post)=> (
              <>
                  {/* Toggle */}
              <Link to={`#${post.id}`} className="d-flex align-items-center h4 mb-0" data-bs-toggle="collapse"  role="button" aria-expanded="true" aria-controls={post.id}>
                  <div className="me-auto">{post.nom} jj</div>
                  {/* Chevron */}
                  <span className="chevron-arrow ms-4">
                  <i className="fe fe-chevron-down fs-4" />
                  </span>
              </Link>
              {/* Row */}
              {/* Collapse */}
              <div className="collapse show" id={post.id} data-bs-parent="#courseAccordion">
                  <div className="py-4 nav" id="course-tabOne" role="tablist" aria-orientation="vertical" style={{display: 'inherit'}}>
                  {post.video?.map((post)=> (
                  <a className="mb-2 d-flex justify-content-between align-items-center text-inherit" id="course-development-tab" data-bs-toggle="pill" href="#course-development" role="tab" aria-controls="course-development" aria-selected="false">
                      <div className="text-truncate">
                      <span onClick={()=>{
                        // url = "http://127.0.0.1:8000" + post.video_url;

                  // <video title="Wildlife" preload="auto" autoplay controls>
                  //   <source src="http://127.0.0.1:8000/media/VID_20231221_164830_zo8Ftsp.mp4" type="video/ogg"/>
                  //   <p>Wildlife</p>
                  //   </video>

                        seturl(
                          '<video title="Wildlife" preload="auto" autoplay controls>'
                        )
                        // alert(url)
                        let url = Base.baseURL + post.video_url;
                        document.querySelector('#video_container').innerHTML = `<video title="Wildlife" preload="auto" autoplay controls>
                        <source src=${url} type="video/ogg"/>
                        <p>Wildlife</p>
                        </video>
                        `;
                        // document.addEventListener('contextmenu', event => event.preventDefault());
                      }} className="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i className="fe fe-play fs-6" /></span>
                      <span>{post.nom}</span>
                      </div>
                      <div className="text-truncate">
                      <span>{post.nombre_heur}</span>
                      </div>
                  </a>
                  ))}
                  
                  </div>
              </div>
              </>
          ))
          : 'Pas de chapitre'
          }
          </li>
          
        </ul>
      </section>
    </main>

    </>
  )
}
